export const columnsForExport = [
  { label: "Facility", key: "facility_name" },
  { label: "District", key: "district_name" },
  { label: "Block", key: "block_name" },
  { label: "Created On", key: "created_on" },
  { label: "Status", key: "open_status" },
  { label: "Open Date", key: "open_date" },
  {label: "Close Date", key: "close_date"},
  { label: "Operational Date", key: "operational_date" },
  { label: "Animals Treated", key: "total_animals_treated" },
  { label: "Animals Fed", key: "total_animals_fed" },
  { label: "Quantity of Fodder (in Kg)", key: "total_fodder" },
];
