import React, { useState, useEffect, useRef, useContext } from "react";
import "../DailyFloodReportComp/Hindi_Font.js";
import "../DailyFloodReportComp/DailyFloodReportCompTable.css";
import Moment from "react-moment";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import govt_logo from "../../../../Assets/Images/bihar_logo.png";
import Loader from "react-spinner-loader";
import ExcelJS from "exceljs";
//import html2pdf from 'html2pdf.js';

import Table from "../../../../Components/UiComponents/Table/Table";

import "../HumanLossReport/HumanLossReportForm.css";

import {
  Editicon,
  Deleteicon,
  Viewicon,
  XCircleblack,
} from "../../../../Components/IconComponents";


import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent.js";
import { postDataWithBody, getData, deleteDataById } from "../../../../Services/Services.js";
import {
  getChildRegionsById,
  findRegionsByUser,
  getDailyReportByDistrict,
  getDailyFloodReportListURL,
  cleardailyReportData,
  getDailyAffectedReportByDistrict,
  getDisasterList,
  getHumanLoss,
  deleteHumanLossPrimaryDetails,
} from "../../../../Services/EndPoints.js";
import { useTranslation } from "react-i18next";
import DatePicker from "../../Common/Form/DatePicker/DatePicker.js";
import { RoleContext } from "../../../../index.js";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup.js";

import HumanLossTable from "../../../../Components/UiComponents/Table/HumanLossTable.js";
import moment from "moment/moment.js";

const HumanLossReportForm = ({ onViewChange }) => {
  const roleName = useContext(RoleContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dailyReport, setDailyReport] = useState(null);
  const [popUp, setPopUp] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [clearId, setClearId] = useState(null);
  const [blockData, setBlockData] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [view, setView] = useState("Primary");
  const [dropdownValue, setDropdownValue] = useState("PDF");  // Track the selected dropdown value
  const pdfRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // State to store selected date
  const [disasterDetails, setDisasterDetails] = useState([]);
  const location = useLocation();
  const { state } = location;  // Destructure the state from the location object

  // Check if 'updated' is true...
  const {updated, date, distName, submitted,viewFromForm} = state || {};
  
  

  const addNewHandler = () => {
    navigate("/my-tasks/create-usertask");
  };




  const getBlockIdListForReport = () => {
    if (slctdBlkId == 0) {
      let blockIdList = [];
      blockData.forEach((v) => blockIdList.push(v.id));
      return blockIdList;
    } else {
      return [slctdBlkId];
    }
  };


  
  const handleViewChange = (event) => {
    const newView = event.target.value;
    setView(newView);
    onViewChange(newView);
  };

  // Call the parent's function when the component mounts to set the initial title
  useEffect(() => {
    onViewChange(view); // Call onViewChange when the component is first rendered
  }, [view, onViewChange]); // Dependency array ensures this runs only on mount
  
    const [tableData, setTableData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    useState()
  
// useEffect(() => {
//     // Dummy data aligned with headers
//     const staticData = [
//       {
//         id: 1,
//         date: "20-12-2024",
//         districtName: "Araria",
//         blockName: "Bhargama",
//         disasterName: "Cold Wave",
//         incidentId: "CW_20241224_001",
//         latitude: "-",
//         longitude: "-",
//         // latitude: "25.12345",
//         // longitude: "85.67890",
//         numberofDeaths: "5",
//         numberofInjured: "10",
//         casualtyData:casualtyData,
//         humanLossNature: "Severe Injury",
//         attachmentPath: "some/path/to/image1",
//       },
//       {
//         id: 2,
//         date: "21-12-2024",
//         districtName: "Araria",
//         blockName: "Jokihat",
//         disasterName: "Lighting",
//         incidentId: "LI_20241224_002",
//         latitude: "26.54321",
//         longitude: "86.12345",
//         numberofDeaths: "2",
//         numberofInjured: "6",
//         casualtyData:casualtyData,
//         humanLossNature: "Fatal",
//         attachmentPath: "some/path/to/image2",
//       },
//     ];
//     setTableData(staticData);
//     setRowCount(staticData.length);
//     setLoader(false);
//   }, []);
  
// const getDailyReportData = async (blockListId) => {
//     if (blockListId == null) {
//       blockListId = getBlockIdListForReport();
//     }
//     var selectedDateStr = document.getElementById("flood_date_picker").value;
//     if (selectedDateStr == "") {
//       selectedDateStr = new Date().toISOString().split("T")[0];
//     }
//     setSelectedDate(selectedDateStr);
//     setLoader(true);

//     const index = blockListId.indexOf(0);
//     if (index > -1) {
//       // only splice array when item is found
//       blockListId.splice(index, 1); // 2nd parameter means remove one item only
//     }
//     let inputobj = {
//       region_id_list: blockListId,
//       reported_on: selectedDateStr,
//     };

//     let dailyReportData = await postDataWithBody(
//       getDailyAffectedReportByDistrict,
//       inputobj,
//       {}
//     );
//     setDailyReport(dailyReportData);

//     setLoader(false);
//     var skillsSelect = document.getElementById("district_drop");
//     var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text;
//     document.getElementById("selected_dist_name").innerHTML = selectedText;

//   };

  async function addImageProcess() {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = govt_logo;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }


  // const casualtyData = [
  //   {
  //     typeOfCasualty: "Injury",
  //     nameOfDeceased: "John Doe",
  //     fathersHusbandsName: "Robert Doe",
  //     age: 45,
  //     gender: "Male",
  //     residentialAddress: "123 Main Street, NY",
  //     victimsActivity: "Crossing the road",
  //     postMortem: "No",
  //     hospitalized: "Yes",
  //     hospitalName: "City Hospital",
  //     reportedToPolice: "Yes",
  //     policeStationName: "Central Police Station",
  //     remarks: "Severe injury",
  //     entryDate: "23-12-2024",
  //     paymentStatus: "Yes",
  //     paymentDate: "24-12-2024"
  //   },
  //   {
  //     typeOfCasualty: "Death",
  //     nameOfDeceased: "Jane Smith",
  //     fathersHusbandsName: "Edward Smith",
  //     age: 30,
  //     gender: "Female",
  //     residentialAddress: "456 Elm Street, CA",
  //     victimsActivity: "Driving",
  //     postMortem: "Yes",
  //     hospitalized: "No",
  //     hospitalName: "N/A",
  //     reportedToPolice: "No",
  //     policeStationName: "N/A",
  //     remarks: "Accident site fatality",
  //     entryDate: "22-12-2024",
  //     paymentStatus: "No",
  //     paymentDate: "N/A"
  //   },
  //     ];

  // Fetch both disaster details and human loss data together
 
  const getDistrictData = async () => {
    try {
      const distList = await getData(findRegionsByUser);

      if (Array.isArray(distList) && distList.length > 0) {
        let blockdata = await getBlockListByDistId(distList[0].id);
        setSlctdBlkId(0);
        let blockIdList = [];
        blockdata.forEach((v) => blockIdList.push(v.id));
        setDistrictData(distList);
        // getDailyReportData(blockIdList);
        setSelectedDistrictId(distList[0].id);
        if(updated){
          const dist = distList.find(dist => dist.value === distName)
          setSelectedDistrictName(dist.value)
          setSelectedDistrictId(dist.id)
          setSelectedDate(date);
          document.getElementById("flood_date_picker").valueAsDate = new Date(date);
          setView(view);
          fetchData(distName);  
        }
        if(submitted){
          const dist = distList.find(dist => dist.value === distName)
          setSelectedDistrictName(dist.value)
          setSelectedDistrictId(dist.id)
          setSelectedDate(date);
          document.getElementById("flood_date_picker").valueAsDate = new Date(date);
          setView(viewFromForm);
          fetchData(distName,viewFromForm);  
        }
  
      } else {
        console.error("Error: District data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  useEffect(() => {
    document.getElementById("flood_date_picker").valueAsDate = new Date();
    getDistrictData()
  }, []);


  
  const fetchData = async (distName = '',viewData = '') => {
    try {
        setLoader(true);

        // Fetch disaster details
        const disasterDetailsResponse = await getData(getDisasterList);
        setDisasterDetails(disasterDetailsResponse);

  
        const selectedDistrict = districtData.find(district => district.id == selectedDistrictId);
        const districtName = distName ? distName : selectedDistrict ? selectedDistrict.value : "";  // Default to empty string if not foun

        var selectedDateStr = document.getElementById("flood_date_picker").value;
      if (selectedDateStr == "") {
      selectedDateStr = new Date().toISOString().split("T")[0];
      }


        console.log(selectedDate,"",view,"MYDETAILS");
        // Fetch human loss details
        const humanLossDetailsResponse = await getData(getHumanLoss.replace("DATE", selectedDateStr).replace("DISTNAME", districtName).replace("VIEW", viewData || view));

        

        
      // Function to format dates as dd/mm/yyyy
      const formatDate = (date) => {
        if (!date) return "NA"; // If the date is null or undefined, return "NA"
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    };
        // Match disasterId with disasterDetails and add disaster_name to human loss details
        const updatedHumanLossDetails = humanLossDetailsResponse.map(item => {
            const disaster = disasterDetailsResponse.find(disaster => disaster.id === item.disasterId);
            const formattedCasualtyData = item.humanLossDetailsList.map(casualty => {
                // Format entryDate and paymentDate fields
                return {
                    ...casualty,
                    entryDate: formatDate(casualty.entryDate),
                    paymentDate: formatDate(casualty.paymentDate),
                };
            });

            return {
                ...item,
                disaster_name: disaster ? disaster.disaster_name : "Unknown", // Handle case when disaster is not found
                casualtyData: formattedCasualtyData,
            };
        });

        // Set data to state
        setTableData(updatedHumanLossDetails);
        setRowCount(updatedHumanLossDetails.length);
    } catch (error) {
        console.error("Error fetching data:", error);
    } finally {
        setLoader(false);
    }
};


  // const tableDataForExcel = [
  //   {
  //     date: "20-12-2024",
  //     district: "Araria",
  //     block: "Bhargama",
  //     disaster: "Cold Wave",
  //     incident_id: "CW_20241224_001",
  //     latitude: "-",
  //     longitude: "-",
  //     deaths: 5,
  //     injured: 10,
  //   },
  //   {
  //     date:"21-12-2024",
  //     district:"Araria",
  //     block:"Jokihat",
  //     disaster: "Lighting",
  //     incident_id: "LI_20241224_002",
  //     latitude: 26.54321,
  //     longitude: 86.12345,
  //     deaths: 2,
  //     injured: 6,
  //   }
  // ];
  const handleDownload = () => {
    if (view === "Primary" && dropdownValue === "Excel") {
      // If the view is "primary" and the dropdown value is "Excel", download Excel
      downloadExcel(tableData, tableHeaders);
    } else if (view === "Primary" && dropdownValue === "PDF") {
      // You can also add a case here for PDF, if required
      downloadPDF();
    } else if (view === "Detailed" && dropdownValue === "PDF") {
      downloadPDF(); // Use the same downloadPDF function, as it handles both primary and detailed PDF
    } else if (view === "Detailed" && dropdownValue === "Excel") {
      generateExcel();
    } else {
      console.log("Invalid combination for download.");
    }
  };

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);  // Update the dropdown value
  };

  // Function to format dates to dd-mm-yyyy
  const formatDate = (date) => {
    if (!date) return "NA"; // If the date is null or undefined, return "NA"
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const downloadPDF = async () => {
    let incrementVal = 20;
    let selectedDate = new Date(
      document.getElementById("flood_date_picker").value
    ).toLocaleDateString("en-GB");
    
    // Conditional orientation based on view type
    const doc = new jsPDF(view === "Detailed" ? "landscape" : "p", "pt", "a4");
     // Conditionally set image based on view
     const image = await addImageProcess(); // Assuming addImageProcess is defined elsewhere
     let fontName = "Helvetica";
 
     // Font and language adjustment for Hindi view
     if (i18n.language == "hi") {
       fontName = "Akshar Unicode";
     }
 
     // Set font for the document
     doc.setFont(fontName);
     doc.setFontSize(13);
 
     // Add image (adjust position and size based on view type)
     if (view === "Detailed") {
       doc.addImage(image, "png", 380, 10, 0, 0);  // Adjust position for landscape view
     } else {
       doc.addImage(image, "png", 255, 25, 0, 0); // For primary view
     }
 
     // Adjust text positioning based on view type
     var xOffset = doc.internal.pageSize.width / 2;
 
     if (view === "Detailed") {
       doc.text(t("floodreport.govt_name"), xOffset, 120, { align: "center" });
       doc.text(t("floodreport.department_name"), xOffset, 140, { align: "center" });
     } else {
       doc.text(t("floodreport.govt_name"), xOffset, 120, { align: "center" });
       doc.text(t("floodreport.department_name"), xOffset, 140, { align: "center" });
     }
 
    // Change the report header text based on view type
    const reportTitle = view === "Detailed" 
      ? "Human Loss - Detailed Report" 
      : "Human Loss - Primary incident Report";
    
    doc.text(reportTitle + " (" + t("floodreport.dated_on_lbl") + " " + selectedDate + ")", 
      xOffset, 160, { align: "center" });
  
     // If the view is "primary", generate PDF from primary data
  if (view === "Primary") {
    // Get the table element
    const table = document.getElementById("report_table");

    // Convert the table data into a format suitable for autoTable
    const tableData = [];
    const tableHeader = [];
    const rows = table.rows;
    const numCols = rows[0].cells.length;

    // Get table header (excluding the Action column)
    for (let i = 0; i < numCols - 1; i++) {  // Skip the last column (Action column)
      tableHeader.push(rows[0].cells[i].textContent.trim());
    }

    // Get table data rows (excluding the Action column)
    for (let i = 1; i < rows.length; i++) {
      const rowData = [];
      for (let j = 0; j < numCols - 1; j++) {  // Skip the last column (Action column)
        rowData.push(rows[i].cells[j].textContent.trim());
      }
      tableData.push(rowData);
    }

    // Generate PDF from the table data without the "Action" column
    doc.autoTable({
      head: [tableHeader],
      body: tableData,
      startX: 10,
      startY: 190,
      theme: "grid",
      rowPageBreak: "avoid",
      bodyStyles: { lineColor: [0, 0, 0] },
      styles: {
        font: fontName,
        fontSize: 9,
        cellPadding: 3,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      columnStyles: {
        2: { cellWidth: 47 }, // Center alignment for these columns
        3: { cellWidth: 47 },
        4: { cellWidth: 47 },
        5: { cellWidth: 47 },
        6: { cellWidth: 47 },
        7: { cellWidth: 47 },
        8: { cellWidth: 47 },
        9: { cellWidth: 47 },
        10: { cellWidth: 47 },
        // Remove Action column style
        // 11: { cellWidth: 0 }, // If Action column exists in the table, this can be adjusted
      },
      didParseCell: function (data) {
        // Ensure we are working with a DOM element
        if (data.cell.raw && data.cell.raw.nodeType === 1) {
          const cellElement = data.cell.raw;
          
          // Check if the cell contains <b> or <span> tags and modify styles accordingly
          if (cellElement.querySelector("b")) {
            data.cell.styles.textColor = "red";
          }
          if (cellElement.querySelector("span")) {
            data.cell.styles.halign = "center";
          }
        }
      },
    });
  }

 
    const formatDateForYy = (date) => {
      if (!date || isNaN(new Date(date).getTime())) return "NA"; // Return "NA" if the date is invalid
    
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = String(d.getFullYear()).slice(-2); // Get last two digits of the year
    
      return `${day}-${month}-${year}`;
    };
    
    const formatDateForYySubTable = (date) => {
      if (!date) return "NA"; // Return "NA" if the date is invalid
      
      // Split the date in the format "dd-mm-yyyy"
      const dateParts = date.split('-');
      if (dateParts.length !== 3) return "NA"; // If the date doesn't have 3 parts (dd, mm, yyyy)
    
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];
    
      // Create a new Date object in yyyy-mm-dd format (JavaScript accepts this format)
      const jsDate = new Date(`${year}-${month}-${day}`);
    
      // Check if the date is valid
      if (isNaN(jsDate.getTime())) {
        return "NA"; // If it's not a valid date, return "NA"
      }
    
      // Extract the day, month, and last 2 digits of the year
      const formattedDay = String(jsDate.getDate()).padStart(2, '0');
      const formattedMonth = String(jsDate.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
      const formattedYear = String(jsDate.getFullYear()).slice(-2); // Get the last two digits of the year (yy)
      
      return `${formattedDay}-${formattedMonth}-${formattedYear}`; // Return the formatted date in dd-mm-yy
    };
    
    

  
// If the view is "detailed", use the detailed table data
if (view === "Detailed") {
  // Loop through the main table data (staticData)
  let serialNumber = 1; // Initialize serial number
  for (let i = 0; i < tableData.length; i++) {
    const row = tableData[i]; // Get the current row of data
    const subtable = row.casualtyData; // Get the corresponding subtable data

    // Render the main table row (one row at a time)
    doc.autoTable({
      head: [
        [
          'S.No.', 'Date', 'District Name', 'Block Name', 'Disaster Name', 
          'Incident ID', 'Latitude', 'Longitude', 'Deaths', 'Injured'
        ]
      ], // Main table headers
      body: [
        [
          serialNumber++, // Add serial number for each row
          formatDateForYy(row.reported_on), row.distName, row.blockName, row.disaster_name, 
          row.incidentId, row.latitude, row.longitude, row.noOfCasualtyDeaths, row.noOfCasualtyInjured
        ], // Main table row data
      ],
      startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 190, // Position of this row, adjust based on last position
      theme: "grid",
      rowPageBreak: "avoid",
      bodyStyles: { lineColor: [0, 0, 0] },
      styles: {
        font: fontName,
        fontSize: 9,
        cellPadding: 3,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      columnStyles: {
        0: { cellWidth: 30 }, // Adjust width of the 'S.No.' column as needed
        1: { cellWidth: 60 },
        2: { cellWidth: 60 },
        3: { cellWidth: 70 },
        4: { cellWidth: 60 },
        5: { cellWidth: 90 },
        6: { cellWidth: 60 },
        7: { cellWidth: 60 },
        8: { cellWidth: 60 },
      },
    });

    let currentY = doc.lastAutoTable.finalY; // Update Y position after rendering main table row

    // Add a gap (for example, 10 units) between the main table and the subtable
    const gapBetweenTables = 7; // Adjust this value as needed
    currentY += gapBetweenTables; // Add gap to the position for subtable

    // Render the subtable (casualtyData) under the current row of the main table
    const subtableHeaders = [
      'Type of Casualty', 'Name of Deceased', 'Father/Husband Name', 'Age', 'Gender', 
      'Residential Address', 'Activity', 'Post Mortem', 'Hospitalized', 'Hospital Name', 
      'Reported to Police', 'Police Station Name', 'Remarks', 'Entry Date', 'Payment Status', 'Payment Date'
    ];

    // Combine headers and subtable rows into a single table
    const subtableData = subtable.map(subRow => [
      subRow.typeOfCasualty, subRow.personName, subRow.personFamilyMemberName, subRow.age, subRow.gender,
      subRow.personAddress, subRow.activityDuringAccident, subRow.isPostMortem ? "Yes" : "No", subRow.isHospitalized ? "Yes" : "No", subRow.hospitalName,
      subRow.isReportedToPS ? "Yes" : "No", subRow.policeStationName, subRow.remarks, formatDateForYySubTable(subRow.entryDate), subRow.paymentStatus, formatDateForYySubTable(subRow.paymentDate)
    ]);

    // Render the entire subtable (headers + rows) at once
    doc.autoTable({
      head: [subtableHeaders], // Subtable headers
      body: subtableData, // Subtable rows
      startY: currentY, // Position of this subtable with added gap
      theme: "grid",
      rowPageBreak: "avoid",
      bodyStyles: { lineColor: [0, 0, 0] },
      styles: {
        font: fontName,
        fontSize: 9,
        cellPadding: 3,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
    });

    // Update the Y position after rendering the subtable
    currentY = doc.lastAutoTable.finalY; // Update position after subtable rows
  }
}

// Final text at the bottom of the page
let finalY = doc.lastAutoTable.finalY; // The y position on the page
doc.setFontSize(10);

// For the 'detailed' view, you might want to adjust positioning to leave space for more content
if (view === "Detailed") {
  doc.text(550, finalY + 40, "Signature of District Officers / Additional District Magistrate", { maxWidth: 300 });
  doc.text(550, finalY + 50, "(ADM) (Disaster Management)/ Officer in Charge", { maxWidth: 300 });
  doc.text(550, finalY + 60, `(District Disaster Management)    [${document.getElementById("district_drop").selectedOptions[0].text}]`, { maxWidth: 300 });

  doc.text(50, finalY + 40, "Letter No.: ", { maxWidth: 300 });
  doc.text(50, finalY + 50, "Date: ", { maxWidth: 300 });
  doc.text(50, finalY + 60, "Copy: ", { maxWidth: 300 });
} 
// For the 'primary' view, place footer text in different positions (may need adjustments)
else {
  doc.text(300, finalY + 150, "Signature of District Officers / Additional District Magistrate", { maxWidth: 300 });
  doc.text(300, finalY + 170, "(ADM) (Disaster Management)/ Officer in Charge", { maxWidth: 300 });
  doc.text(300, finalY + 190, `(District Disaster Management)  [${document.getElementById("district_drop").selectedOptions[0].text}]  `, { maxWidth: 300 });

  doc.text(50, finalY + 150, "Letter No.: ", { maxWidth: 300 });
  doc.text(50, finalY + 170, "Date: ", { maxWidth: 300 });
  doc.text(50, finalY + 190, "Copy: ", { maxWidth: 300 });
}


// Save the PDF with an appropriate file name based on the view
const fileName = view === "Detailed" 
  ? "Human_loss_detailed_report.pdf" 
  : "Human_loss_primary_report.pdf";

  console.log("Saving PDF with filename:", fileName);
  
  doc.save(fileName);

  };
  
  

  const downloadExcel = async (tableData, tableHeaders, fileName = "Human_loss_primary_report.xlsx") => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Human Loss Primary Report");
  
    
    // Define columns based on tableHeaders
    worksheet.columns = tableHeaders.map((header) => ({
      header: header.label,
      key: header.key,
      width: 25, // Adjust as needed
    }));
  
    
      // Process data and replace null or undefined with "NA"
      const processedData = tableData.map((row, index) => {
        const processedRow = { slno: index + 1 }; // Add Sl. No dynamically
    
        // Iterate over the headers and replace missing values with "NA"
        tableHeaders.forEach((header) => {
          const cellValue = row[header.key];
    
          // Skip the slno column
          if (header.key !== "slno") {
            if (header.key.includes("reported_on") && cellValue) {
              processedRow[header.key] = formatDate(cellValue); // Format the date fields
            } else {
              // Check if the cell value is null or undefined, or if it's not present in the row
              processedRow[header.key] = (cellValue !== undefined && cellValue !== null && cellValue !== "") ? cellValue : "NA";
            }
          }
        });
        
        return processedRow;
      });
    
  
    // Add rows to the worksheet
    processedData.forEach((row) => worksheet.addRow(row));
  
    // Style header row
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });
  
    // Generate and save the Excel file
    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Failed to export Excel file:", error);
    }
  };
  
  const tableHeaders = [
    { label: "Date", key: "reported_on" },
    { label: "Name of the District", key: "distName" },
    { label: "Block", key: "blockName" },
    { label: "Name of Disaster", key: "disaster_name" },
    { label: "Incident ID", key: "incidentId" },
    { label: "Latitude", key: "latitude" },
    { label: "Longitude", key: "longitude" },
    { label: "Number of Deaths", key: "noOfCasualtyDeaths" },
    { label: "Number of Injured", key: "noOfCasualtyInjured" },
  ];
  
  
  const getFormattedDate = (dateStr) => {
    return <Moment format="DD/MM/YYYY">{dateStr}</Moment>;
  };

  const getBlockListByDistId = async (distId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [distId],
        {}
      );
      blockList.forEach(function (elem, index) {
        elem["value"] = elem["name"];
      });
      blockList.unshift({ id: 0, value: "All" });
      setBlockData(null);
      setTimeout(function () {
        setBlockData(blockList);
      }, 100);
      return blockList;
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };
  async function getDistrictDataByCode(event) {
    setSelectedDistrictName(event.target.value);
    setSelectedDistrictId(event.target.value);
    getBlockListByDistId(event.target.value);
  }
  function setSelectedBlockIdVal(event) {
    setSlctdBlkId(event.target.value);
  }
  const changeSelectedate = () => {
    /*if (selectedDistrictId && selectedDate) {
      getDailyReportData(selectedDistrictId, selectedDate);
    } else {
      console.error("Please select both district and date.");
    }*/
  };

  const handleTogglePopup = () => {
    setPopUp(!popUp);
  };

  const onClickSucesshandler = async () => {
    await postDataWithBody(cleardailyReportData + clearId, {}, {});
    setPopUp(false);
  };


  const columns = React.useMemo(
    () => [
      {
        Header: t("Date"),
        accessor: "reported_on",
        Cell: (props) => <Moment format="DD/MM/YYYY">{props.value}</Moment>,
      },
      
      {
        Header: t("Name of the District"),
        accessor: "distName",
      },
      {
        Header: t("Block"),
        accessor: "blockName",
      },
      {
        Header: t("Name of Disaster"),
        accessor: "disaster_name",
      },
      {
        Header: t("Incident ID"),
        accessor: "incidentId",
      },
      {
        Header: t("Latitude"),
        accessor: "latitude",
        Cell: (props) => (props.value ? props.value : "NA"), // Show "NA" if no latitude
      },
      {
        Header: t("Longitude"),
        accessor: "longitude",
        Cell: (props) => (props.value ? props.value : "NA"), // Show "NA" if no longitude
      },
      {
        Header: t("Number of Deaths"),
        accessor: "noOfCasualtyDeaths",
      },
      {
        Header: t("Number of Injured"),
        accessor: "noOfCasualtyInjured",
      },
      view === "Detailed" && {
        Header: t("Add Details"),
        disableSortBy: true,
        Cell: (props) => {
          // Check if the report date is older than 3 days
        const isOlderThanThreeDays = (reportDate) => {
          const currentDate = new Date();
          const selectedDateObj = new Date(reportDate);
          const diffInTime = currentDate.getTime() - selectedDateObj.getTime();
          const diffInDays = diffInTime / (1000 * 3600 * 24);
          return diffInDays > 3;
        };

        const isSuperAdmin = roleName.roleName === process.env.REACT_APP_SUPER_ADMIN;
      const isDisabled = !isSuperAdmin && isOlderThanThreeDays(props.row.original.reported_on); 
        // const isDisabled = isOlderThanThreeDays(props.row.original.reported_on);
          return (
            <div className="hl-usertable__actionsicon">
  <span
    className={`icon__position ${isDisabled ? 'disabled' : ''}`}
    onClick={() => {
      if (!isDisabled) {
      navigate("/human-loss/registration-form", {
        state: {
          data: props.row.original, // Pass data to the next page
        },
      });
    }
      console.log("Human Loss Row Data:", props.row.original); // Log data after navigate
    }}
  >
    <Editicon value={props.value} className={`cursor-pointer me-2 ${isDisabled ? 'disabled_icon' : ''}`} />
  </span>
</div>

          );
        },
      },

      {
        Header: t("dailytaskmgmnttableheaders.action"),
        disableSortBy: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const [selectedId, setSelectedId] = useState(null);
      
          const deletePopUp = (id) => {
            setSelectedId(id);
            setIsdelete(!isdelete);
          };

          const deleteTableDataById = async () => {
            try {
              const response = await deleteDataById(deleteHumanLossPrimaryDetails, selectedId);
              if (response?.status === "success") {
                const districtNameForDelete = districtData.find(dist => dist.id == selectedDistrictId)?.name
                await fetchData(districtNameForDelete);
                toast.success(
                  response.msg || t("delete_success_message"),
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                  }
                );
              } else {
                toast.error(
                  response.msg || t("delete_failure_message"),
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                  }
                );
              }
            } catch (error) {
              toast.error(t("delete_error_message"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
              console.error("Delete error:", error);
            } finally {
              setIsdelete(false);
              setSelectedId(null);
            }
          };

           // Function to check if the report date is older than 3 days
        const isOlderThanThreeDays = (reportDate) => {
          const currentDate = new Date();
          const selectedDateObj = new Date(reportDate);
          const diffInTime = currentDate.getTime() - selectedDateObj.getTime();
          const diffInDays = diffInTime / (1000 * 3600 * 24);
          return diffInDays > 3;
        };


        const isSuperAdmin = roleName.roleName === process.env.REACT_APP_SUPER_ADMIN;
      const isDisabled = !isSuperAdmin && isOlderThanThreeDays(props.row.original.reported_on); 
        //    // Check if the report date is older than 3 days
        // const isDisabled = isOlderThanThreeDays(props.row.original.reported_on);

          return (
            <div style={{ alignItems: "center" }}>
              {/* <span
                onClick={() => {
                  navigate("", {
                    state: {
                      data: props.row.original,
                      // isTextFieldDisable: isTextFieldDisable,
                    },
                  });
                }}
                style={{ cursor: "pointer", marginRight: "10px" }}
              >
              </span> */}
              <span onClick={() => {
    if (!isDisabled) {
      deletePopUp(props.row.original.id);
    }
  }}
                style={{
                  cursor: isDisabled ? "not-allowed" : "pointer",
                  marginRight: "10px",
                }}>
                <Deleteicon className={`cursor-pointer ${isDisabled ? "disabled_icon" : ""}`}/>
              </span>
              {isdelete && (
                <Confirmpopup
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={() => deletePopUp(null)}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>

          );
        },
      }
    ].filter(Boolean), // Filter out any undefined values (e.g., if view !== "detailed")
    [t, roleName, isDeletePopupVisible, view, navigate] // Add view to the dependency array
  );

  
  const generateExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Human Loss Detailed Report');

    // Define columns for the main table (excluding casualty columns)
    const mainColumns = [
        'S.NO.', 'Date', 'District Name', 'Block Name', 'Disaster Name', 'Incident ID',
        'Latitude', 'Longitude' // Removed 'Number of Deaths', 'Number of Injured', and 'Attachment Path'
    ];

    // Define columns for the sub-table (casualty data)
    const casualtyColumns = [
        'Type of Casualty', 'Name of Deceased/Injured', 'Father\'s/Husband\'s Name', 'Age',
        'Gender', 'Residential Address', 'Victim\'s Activity', 'Post Mortem', 'Hospitalized', 'Hospital Name',
        'Reported to Police Station', 'Police Station Name', 'Remarks', 'Entry Date', 'Payment Status', 'Payment Date'
    ];

    // Combine the main and sub-table columns for the worksheet
    const allColumns = [...mainColumns, ...casualtyColumns];
    worksheet.columns = allColumns.map(col => ({ header: col, key: col, width: 20 }));

    let serialNumber = 1; // Initialize serial number

    // Add rows for main table and corresponding sub-table rows for each casualty
    tableData.forEach(data => {
        if (data.casualtyData && data.casualtyData.length > 0) {
            // If there are casualty rows, add the main row and the corresponding sub-table rows
            data.casualtyData.forEach(casualty => {
                const casualtyRow = [
                    serialNumber++, // Increment serial number for each row
                    formatDate(data.reported_on), data.distName, data.blockName, data.disaster_name, data.incidentId,
                    data.latitude, data.longitude, // Main table data excluding the hidden fields

                    // Append the casualty-specific data
                    casualty.typeOfCasualty, casualty.personName, casualty.personFamilyMemberName, casualty.age,
                    casualty.gender, casualty.personAddress, casualty.activityDuringAccident, casualty.isPostMortem ? "Yes" : "No",
                    casualty.isHospitalized ? "Yes" : "No", casualty.hospitalName, casualty.isReportedToPS ? "Yes" : "No", casualty.policeStationName,
                    casualty.remarks, casualty.entryDate, casualty.paymentStatus, casualty.paymentDate
                ];

                // Add each casualty row
                worksheet.addRow(casualtyRow);
            });
        } else {
            // If there are no casualty rows, add just the main row without sub-table data
            const mainRow = [
                serialNumber++, // Increment serial number for each row
                formatDate(data.reported_on), data.distName, data.blockName, data.disaster_name, data.incidentId,
                data.latitude, data.longitude, // Main table data excluding the hidden fields
                ...Array(casualtyColumns.length).fill('') // Fill empty cells for the casualty data
            ];

            // Add the main row without casualties
            worksheet.addRow(mainRow);
        }
    });

    // Save the workbook
    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Human_loss_detailed_report.xlsx';
        link.click();
    });
};




  return (
    <>
    <div class="flood__report--container">
      {popUp && (
        <Confirmpopup
          show={popUp}
          handleToggle={handleTogglePopup}
          title="Confirmation"
          message="Are You sure to clear"
          cancel="No"
          success="Yes"
          onClickSucesshandler={onClickSucesshandler}
        />
      )}
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="ps-2 d-flex">
        <div className="me-2 region_type_container">
          <label className="mb-2">{t("floodreport.district")}</label>
          <div>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="GOPALGANJ"
              options={districtData}
              onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        </div>

        {/* <div className="me-2 region_type_container">
          <label className="mb-2">Block</label>
          <SelectComponent
            id="block_drop"
            value={selectedBlockId}
            onChange={setSelectedBlockIdVal}
            placeholder="All Blocks"
            options={blockData}
          />
        </div> */}

        <div>
          <label className="mb-2">{t("floodreport.reported_on")}</label>
          <div className="date_picker_flood_container me-1">
            <DatePicker
              selected={selectedDate}
              maxDate={new Date().toISOString().split("T")[0]}
              name="flood_date_picker"
              onChange={(date) => {
                console.log("Selected date:", date);
                setSelectedDate(date);
              }}
              className="form-control date-picker date_picker_flood"
            />
          </div>
        </div>
        {/* <select
        className="btn btn-success ms-2 btn-sm flood__report--button"
        onChange={handleViewChange}
        value={view}
      >
        <option value="primary">Primary</option>
        <option value="detailed">Detailed</option>
      </select> */}
      <select onChange={handleViewChange} value={view} className="btn btn-success ms-2 btn-sm flood__report--button hl-select-ddwn">
        <option value="Primary">Primary</option>
        <option value="Detailed">Detailed</option>
      </select>
        <button
          className="btn btn-success ms-2 btn-sm flood__report--button"
          onClick={() => fetchData()}>
          {t("floodreport.buttonapply")}
        </button>
        <select 
          onChange={handleDropdownChange} 
          value={dropdownValue} 
          className="btn btn-success ms-2 btn-sm flood__report--button hl-select-ddwn"
        >
          <option value="PDF">PDF</option>
          <option value="Excel">Excel</option>
        </select>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={handleDownload}>
          {t("floodreport.download")}
        </button>
        {/* <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => editFloodData()}
          disabled={rolName !== process.env.REACT_APP_SUPER_ADMIN}>
          {t("floodreport.edit_btl_lbl")}
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => clearFloodData()}
          disabled={rolName !== process.env.REACT_APP_SUPER_ADMIN}>
          {t("floodreport.clear_btl_lbl")}
        </button> */}
      </div>
     

</div>
        <div className="datatable__container" >

        {view === "Primary" && (

        <Table
        className="hl-primary-detailed-tbl"
            data={tableData}
            addButtonLabel={t("dailytaskaddbuttonlabel")}
            columns={columns}
            numberOfRows={7}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF"}}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />        
        )}
        {view === "Detailed" && ( 
            <HumanLossTable
              data={tableData}
              setSearchData={setSearchData}
              columns={columns}
              rowCount={rowCount}
              numberOfRows={10}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{ border: "1px solid  #f0f0f0", borderRadius: "12px" }}
              getTableData = {fetchData}
            />
          )}
        </div>


        {/* </div> */}
      
      </>
  );
};

export default HumanLossReportForm;


