import * as yup from "yup";
export const schema = [
  yup.object({
    // typeCasualty: yup.string()
    // .required('Type of Casualty is required')
    // .oneOf(["0", "1"], "Invalid type of casualty"), // Ensure it's either "Death" (0) or "Injured" (1)
  
  nameDeceased: yup.string()
    .required('Name of Deceased/Injured person is required')
    .min(2, 'Name must be at least 2 characters')
    .max(100, 'Name must be at most 100 characters'), // Optional: You can set limits on the length of name
    
    fathersName: yup.string()
    .required(`Father's Name/ Husband's Name is required`)
    .min(2, 'Name must be at least 2 characters')
    .max(100, 'Name must be at most 100 characters'), // Optional: You can set limits on the length

    // age: Validation for the age field
    age: yup.number()
      .nullable() // Allows null values
      .transform((value, originalValue) => (originalValue === '' ? null : value)) // Transforms empty strings to null
      .required('Age is required') // Ensures age is entered
      .min(1, 'Age must be at least 1') // Minimum age of 1
      .max(120, 'Age must be less than or equal to 120') // Max age of 120
      .integer('Age must be an integer') // Ensures age is an integer
      .positive('Age must be a positive number'), // Ensures age is positive

      // hospitalName: yup
      // .string()
      // .when("hospitalized", {
      //   is: (hospitalized) => hospitalized === "True", // Only validate when hospitalized is "True"
      //   then: yup
      //     .string()
      //     .required("Hospital Name is required")
      //     .min(2, 'Hospital Name must be at least 2 characters long')
      //     .max(200, 'Hospital Name must be at most 200 characters long')
      //     .matches(/^[a-zA-Z0-9\s]*$/, 'Hospital Name can only contain letters, numbers, and spaces'),
      //   otherwise: yup.string().notRequired(), // No validation if hospitalized is "False"
      // }),
    
 
      paymentDate: yup.string()
      .when(['paymentStatus', 'entryDate'], {
        is: (paymentStatus, entryDate) => paymentStatus === "0" && entryDate, // Only validate when payment status is "Yes" and entryDate exists
        then: () => yup.string()
          .required('Payment Date is required when Payment Status is Yes')
          .test('date-comparison', 
            'Payment Date must be equal to or greater than Entry Date', 
            function(value) {
              const entryDate = this.parent.entryDate;
              if (!value || !entryDate) return true; // Skip validation if either date is missing
              
              // Convert both dates to timestamps for comparison
              const paymentTimestamp = new Date(value).getTime();
              const entryTimestamp = new Date(entryDate).getTime();
              
              return paymentTimestamp >= entryTimestamp;
            }
          ),
        otherwise: () => yup.string()
          .test('no-date-if-not-yes', 
            'Payment Date can only be selected when Payment Status is Yes', 
            function(value) {
              // If there's a date value and status is not "Yes", return error
              if (value && value.trim() !== '') {
                return false;
              }
              return true;
            }
          )
      })
  }),
];

export const PrimaryConfigSchema = [
  yup.object({
    Latitude: yup
  .string()
  .transform((value, originalValue) => String(originalValue))
  .when('nameOfDisaster', {
    is: 'Lightning',
    then: () => yup.string()
      .required("Latitude is required for Lightning incidents")
      .matches(
        /^-?\d+(\.\d+)?$/, // Regex to match numbers, including decimals and negative numbers
        "Latitude must be a valid number"
      )
  }),

longitude: yup
  .string()
  .transform((value, originalValue) => String(originalValue))
  .when('nameOfDisaster', {
    is: 'Lightning',
    then: () => yup.string()
      .required("Longitude is required for Lightning incidents")
      .matches(
        /^-?\d+(\.\d+)?$/, // Regex to match numbers, including decimals and negative numbers
        "Longitude must be a valid number"
      )
  }),

  numberOfDeaths: yup
  .number()
  .transform((value, originalValue) => {
    // Convert empty string to NaN (if the field is empty)
    return originalValue === "" ? NaN : Number(originalValue);
  })
  .required("Number of Deaths is required")
  .typeError("Number of Deaths must be a number"),

// Number of Injured (always required)
numberOfInjured: yup
  .number()
  .transform((value, originalValue) => {
    // Convert empty string to NaN (if the field is empty)
    return originalValue === "" ? NaN : Number(originalValue);
  })
  .required("Number of Injured is required")
  .typeError("Number of Injured must be a number"),
  }),
];


export const HumanLossPrimaryConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "date",
        label: "Date",
        type: "input",
        style: { width: "200px" },
        disabled: true
      },
      {
        name: "nameOfDistrict",
        label: "Name of District",
        type: "input",
        style: { width: "340px" },
        disabled: true
      },
      {
        name: "block",
        label: "Block",
        type: "input",
        style: { width: "280px" },
        disabled: true
      },
      {
        name: "nameOfDisaster",
        label: "Name of Disaster",
        type: "input",
        style: { width: "340px" },
        disabled: true
      },
      {
        name: "incidentId",
        label: "Incident Id",
        type: "input",
        style: { width: "340px" },
        disabled: true
      },
      {
        name: "incidentDate",
        label: "Incident Date",
        type: "date",
        style: { width: "200px" },
        disabled: true
      },
      {
        name: "incidentTime",
        label: "Incident Time",
        type: "input",
        style: { width: "340px" },
        disabled: true
      },
      {
        name: "Latitude",
        label: "Latitude",
        type: "input",
        style: { width: "340px" },
        mandatory: (values) => values.nameOfDisaster === 'Lightning'
        // mandatory: "true",
        // validation: yup
        //   .string()
        //   .when("nameOfDisaster", {
        //     is: "Lightning",
        //     then: yup.string().required("Latitude is required"),
        //     otherwise: yup.string().notRequired(),
        //   }),
      },
      {
        name: "longitude",
        label: "Longitude",
        type: "input",
        style: { width: "280px" },
        mandatory: (values) => values.nameOfDisaster === 'Lightning'
        // mandatory: "true",
        // validation: yup
        //   .string()
        //   .when("nameOfDisaster", {
        //     is: "Lightning",
        //     then: yup.string().required("Longitude is required"),
        //     otherwise: yup.string().notRequired(),
        //   }),
      },
      {
        name: "numberOfDeaths",
        label: "Number of Deaths",
        type: "input",
        style: { width: "340px" },
        mandatory: "true",
      },
      {
        name: "numberOfInjured",
        label: "Number of Injured",
        type: "input",
        style: { width: "340px" },
        mandatory: "true",
      },
      {
        name: "policeStationOfIncident",
        label: "Police Station of Incident",
        type: "select",
        options: [
          { value: "0", label: "Bhargawan" },
          { value: "1", label: "Bihia" },
        ],
        style: { width: "280px" },
        // mandatory: "true",
      },
      {
        name: "addressOfIncident",
        label: "Address of Incident",
        type: "input",
        style: { width: "340px" },
        // mandatory: "true",
        maxLength: null
      },

    ],
  },
];

export const HumanLossUserConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "typeCasualty",
        label: "Type of Casualty",
        type: "select",
        options: [
              { value: "0", label: "Death" },
              { value: "1", label: "Injured" },
            ],
        mandatory: "true",
      },
      {
        name: "nameDeceased",
        label: "Name of Deceased/ Injured person",
        type: "input",
        mandatory: "true",
      },
      {
        name: "age",
        label: "Age",
        type: "input",
        mandatory: "true",
      },
      {
        name: "sex",
        label: "Gender",
        type: "select",
        options: [
          { value: "0", label: "Male" },
          { value: "1", label: "Female" },
          { value: "2", label: "Transgender" },
        ],
        mandatory: "true",
      },
      {
        name: "fathersName",
        label: "Father's Name/ Husband's Name",
        type: "input",
        mandatory: "true",
      },
      {
        name: "residentialAddress",
        label: "Residential Address of Deceased person/ Injured person",
        type: "input",
        maxLength: null
      },
      {
        name: "activityAccident",
        label: "Victim's Activity during accident",
        type: "input",
      },
      {
        name: "postMortem",
        label: "Post Mortem",
        type: "select",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" },
        ],
        // mandatory: "true",
      },
      {
        name: "hospitalized",
        label: "Hospitalized",
        type: "select",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" },
        ],
        mandatory: "true",
      },
      {
        name: "hospitalName",
        label: "Hospital Name",
        type: "input",
        // mandatory: "true",
        maxLength: null
      },
      {
        name: "reportedPoliceStation",
        label: "Reported To Police Station",
        type: "select",
        options: [
          { value: "True", label: "True" },
          { value: "False", label: "False" },
        ],
        mandatory: "true",
      },
      {
        name: "policeStationName",
        label: "Police Station Name",
        type: "select",
        options: [
          { value: "0", label: "Bhargawan" },
          { value: "1", label: "Bihia" },
        ],
        mandatory: "true",
      },
      
      {
        name: "entryDate",
        label: "Entry Date",
        type: "date",
        mandatory: "true",
      },
      {
        name: "paymentStatus",
        label: "Payment Status Yes/No ",
        type: "select",
        options: [
          { value: "0", label: "Yes" },
          { value: "1", label: "No" },
          { value: "2", label: "NA" },
        ],
        mandatory: "true",
      },
      {
        name: "paymentDate",
        label: "Payment Date",
        type: "date",
        mandatory: (values) => values.paymentStatus === "0", 
      },
      {
        name: "remarks",
        label: "Remarks",
        type: "text_area",
      },















      // {
      //   name: "task_type",
      //   label: "taskmgmntformlabels.tasktype",
      //   type: "select",
      //   options: [
      //     { value: "0", label: "Activity" },
      //     { value: "1", label: "Notification" },
      //   ],
      // },
      // {
      //   name: "assign_type",
      //   label: "taskmgmntformlabels.assigntype",
      //   type: "select",
      //   options: [
      //     { value: "0", label: "Group" },
      //     { value: "1", label: "Individual" },
      //   ],
      // },
      // {
      //   name: "role_list",
      //   label: "taskmgmntformlabels.rolelist",
      //   type: "multi_select",
      //   mandatory: "true",
      // },
      // {
      //   name: "user_list",
      //   label: "taskmgmntformlabels.userlist",
      //   type: "multi_select",
      //   mandatory: "true"
      // },

      // {
      //   name: "description",
      //   label: "taskmgmntformlabels.summary",
      //   type: "text_area",
      // },
    ],
  },
];
