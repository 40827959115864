import React from "react";
import { useState } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../../../Components/UiComponents/GridItem/GridItem";
import DMSHeader from "../../../DMSHeader/DMSHeader";
import { useLocation } from "react-router-dom";
import HumanLossRegistrationForm from "./HumanLossRegistrationForm";
import { useTranslation } from "react-i18next";
import HumanLossPrimaryRegistrationForm from "./HumanLossPrimaryRegistrationForm";

const HumanLossForm = (props) => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { state } = useLocation();
  let edit = state?.edit;
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: <DMSHeader title={t("Human Loss Form")}
       showIconsForback={true}/>,
    },
    // Only include this layout item if edit is false...
  ...(!edit ? [{
    x: 0,
    y: 1,
    width: 12,
    height: 10,
    id: 2,
    component: <HumanLossPrimaryRegistrationForm />,
  }] : []),
    {
      x: 0,
      y: 2,
      width: 12,
      height: 17,
      id: 3,
     
      component: (
        <HumanLossRegistrationForm/>
      ),
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 ">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default HumanLossForm; 
