import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import  "./UserTaskManagement.css";
import { useKeycloak } from "@react-keycloak/web";
import { toast, ToastContainer } from "react-toastify";
import {
  TaskMgntFormConfig,
  usertaskmgntschema
} from "../../../../JsonData/CreateTaskMgntConfig";
import {
  postDataWithBody,
  getData,
  
  putDataWithBody,
  postDataWithBodyFrGIS,
} from "../../../../Services/Services";
import {
  getUsersFromApp,
  getAllRole,
  createTaskandAssign,
  createNewTask
} from "../../../../Services/EndPoints";
import { yupResolver } from "@hookform/resolvers/yup";

const CreateUserTaskForm = () => {
  const { keycloak } = useKeycloak();
  const [activeTab, setActiveTab] = useState(0);
  const [loader, setLoader] = useState(false);
  const [taskMgntFormConfig, setTaskMgntFormConfig] = useState(
    TaskMgntFormConfig
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const {isTextFieldDisable } = location.state || {};
  let data = state?.data;

  const [lgShow, setLgShow] = useState(false);

 
  const {
    register,
    handleSubmit,
    setValue,
    getValues,

    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(usertaskmgntschema[activeTab]),
    mode: "all",
  });
  const assign_type = useWatch({
    name: "assign_type",
    control,
  });
  

  useEffect(() => {
    document.getElementById("container_user_list").classList.add('disabled_dropdown');
    getUserList();
    fetchAllRoles();
    if(data){
      document.getElementById("submitBtn").style.display="none";
      reset(data);
      console.log(data,"data.......................")
    }
  }, []);

 
  
  const getUserList = async () => {
    setLoader(true);
    var userIdNameMap = {};
    var userList = await getData(getUsersFromApp);
    (userList).sort((a, b) => (a.name > b.name) ? 1: -1);
    let userArrSanitized = [];
    userList.forEach((user, index) => {
      userIdNameMap[user.id] = user.first_name+" "+user.last_name;
      userArrSanitized.push({
        label: user.first_name+" "+user.last_name,
        value: user.id,
      });
    });
    TaskMgntFormConfig[0].fields[5]["options"] = userArrSanitized;
    setTaskMgntFormConfig(TaskMgntFormConfig);
    populateUserList(userIdNameMap);
  };

  const populateUserList = (userIdNameMap)=>{
    let userListToPrePopulate = [];
    if(data){
      var userListFrmEdit = data.user_list.split(",");
      for(let user in userIdNameMap){
        if(userListFrmEdit.indexOf(user)>-1){
          userListToPrePopulate.push({"label":userIdNameMap[user],"value":user});
        }
      }
      setValue("user_list",userListToPrePopulate);
    }
  }
  const populateRoleList = (roleIdNameMap)=>{
    let roleListToPrePopulate = [];
    if(data){
      var roleListFrmEdit = data.role_list.split(",");
      for(let role in roleIdNameMap){
        if(roleListFrmEdit.indexOf(role)>-1){
          roleListToPrePopulate.push({"label":roleIdNameMap[role],"value":role});
        }
      }
      setValue("role_list",roleListToPrePopulate);
      console.log(roleListToPrePopulate,"..........")
    }
  }
  const fetchAllRoles = async () => {
    setLoader(true);
    let rolearr = await getData(getAllRole);
    (rolearr).sort((a, b) => (a.name > b.name) ? 1: -1);
    let roleArrSanitized = [];
    var roleIdNameMap = {};
    rolearr.forEach((role, index) => {
      roleIdNameMap[role.id] = role.name;
      roleArrSanitized.push({
        label: role.name,
        value: role.id,
      });
    });
    TaskMgntFormConfig[0].fields[4]["options"] = roleArrSanitized;
    setTaskMgntFormConfig(TaskMgntFormConfig);
    //setValue("role_list", rolearr);
    setLoader(false);
    populateRoleList(roleIdNameMap);
  };
  if(assign_type && assign_type == 1){
    document.getElementById("container_user_list").classList.remove('disabled_dropdown');
    document.getElementById("container_role_list").classList.add('disabled_dropdown');
    document.getElementById("assign_task").classList.add('disabled_dropdown');

    setValue("role_list", []);
    setValue("assign_task", "")
  }
  else if(assign_type && assign_type == 0){
    document.getElementById("container_user_list").classList.add('disabled_dropdown');
    document.getElementById("container_role_list").classList.remove('disabled_dropdown');
    document.getElementById("assign_task").classList.remove('disabled_dropdown');
    setValue("user_list",[]);
    // setValue("assign_task")
  }

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        
          <FormHandler
            register={register}
            errors={errors}
            fields={fields}
            control={control}
            columns={2}
            getValues={getValues}
            isTextFieldDisable={isTextFieldDisable}
          />
     
      </div>
    );
  };

  

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  
  const submitHandler = async (data, e) => {
    e.preventDefault(); // Prevent default form submission
  
    const formData = new FormData();
  
    // Append common fields
    formData.append("user_full_name", keycloak.idTokenParsed.name);
    formData.append("email", keycloak.idTokenParsed.email);
  
    // Process and append user_list as a comma-separated string
    if (data.user_list) {
      const userList = data.user_list.map((user) => user.value).join(",");
      formData.append("user_list", userList); // Append as a single string
    }
  
    // Process and append role_list as a comma-separated string
    if (data.role_list) {
      const roleList = data.role_list.map((role) => role.value).join(",");
      formData.append("role_list", roleList); // Append as a single string
    }
  
    // Add any other data fields
    for (let key in data) {
      if (!["user_list", "role_list"].includes(key)) {
        formData.append(key, data[key]);
      }
    }
  
    setLoader(true); // Show loader
  
    // Make the API call
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await postDataWithBody(createNewTask, formData, headers);
  
    // Handle response
    if (res.status === "success") {
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setLoader(false);
      navigate("/my-tasks/view-usertask");
    } else {
      toast.error(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setLoader(false);
    }
  };
  
  

  const tabsData = generateTabsData(taskMgntFormConfig);
  return (
    <div className="mt-4 facility-container">
    

      <div className="form-body">
        <div className="registration__form--body">
          <form
            onSubmit={handleSubmit(submitHandler)}
            name="CreateFacilityRegForm"
          >
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" id="submitBtn" className="mx-2" variant="primary">
                  Save & Submit
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
      
    </div>
  );
};

export default CreateUserTaskForm;
