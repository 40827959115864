import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Loader from "react-spinner-loader";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { GridStack } from "gridstack";
import FamilyAffectedTrendView from "../FamilyAffectedTrendView";
import DoughnutChart from "../DoughnutChart";
import { getData, postDataWithBody } from "../../../../Services/Services";
import {
  getStateFloodCountData,
  getBlockWiseReport,
  getAffectedBlock,
  getAffectedDistrict,
  getAffectedNagarNigam,
  getAffectedPanchayat,
  getAffectedDrilldownView,
  getCurrStateFloodCountData,
  getCurrBarChartData,
} from "../../../../Services/EndPoints";
import "./StateDashboard.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Expand, MapIcon } from "../../../../Components/IconComponents";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import DashboardCard from "./DashboardCard/DashboardCard";
import { currentCardConfig, dashboardCardConfig } from "./StateDashboardConfig";
import LMSButton from "../../Common/LMSButton/LMSButton";
import HalfDoughnutChart from "../HalfDoughnutChart";
import HorizontalBarChart from "../HorizontalBarChart";
import CurrentDashboardCard from "../CurrentDashboardCard/CurrentDashboardCard";
import TabSwitch from "../TabSwitch/TabSwitch";
import { useNavigate } from "react-router-dom";
import WeatherDataTable from "../WeatherDataTable";
import ToggleViewBmsk from "../ToggleView/ToggleViewBmsk";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Popover } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

const StateDashboard = () => {
  const handle = useFullScreenHandle();
  const [clickedKey, setClickedKey] = useState("");
  const [cardData, setCardData] = useState(null);
  const [currCardData, setCurrCardData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fromDate, setFromDate] = useState(
    new Date(process.env.REACT_APP_FLD_DASHBOARD_START_DATE)
  );
  const [toDate, setToDate] = useState(new Date());
  const [horizontalChartTitle, setHorizontalChartTitle] = useState("");
  const [drildownAPIkey, setDrildownAPIkey] = useState("");
  const [drillDownData, setDrillDownData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [affectedDistrictData, setAffectedDistrictData] = useState([]);
  const [affectedBlockData, setAffectedBlockData] = useState([]);
  const [affectedNagarNigamData, setAffectedNagarNigamData] = useState([]);
  const [affectedPanchayatData, setAffectedPanchayatData] = useState([]);
  const [activeTab, setActiveTab] = useState("dss");
  const [activeTabBmsk, setActiveTabBmsk] = useState("BMSK");
  const [activeKeyName, setactiveKeyName] = useState("");
  const [apiKeyName, setApiKeyName] = useState({});
  const [error, setError] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2025);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFromDateChange = (date) => {
    if (toDate && date > toDate) {
      setError(true);
      setFromDate(date);
    } else {
      setError(false); // Clear error if the dates are valid
      setFromDate(date);
    }
  };

  const handleToDateChange = (date) => {
    if (fromDate && date < fromDate) {
      setError(true);
      setToDate(date);
    } else {
      setError(false); // Clear error if the dates are valid
      setToDate(date);
    }
  };

  const aggregateData = (data) => {
    const aggregatedData = data.reduce((acc, current) => {
      const key = `${current.reported_on_date}-${current.name}-${current.id}`;

      if (!acc[key]) {
        acc[key] = {
          reported_on_date: current.reported_on_date,
          name: current.name,
          count: current.count,
          id: current.id,
          blk_name: [current.blk_name], // Start with the first block name
        };
      } else {
        acc[key].count += current.count;
        acc[key].blk_name.push(current.blk_name);
      }

      return acc;
    }, {});

    return Object.values(aggregatedData).map((item) => ({
      ...item,
      blk_name: item.blk_name.join(", "), // Join block names with comma
    }));
  };

  const navigateToGisManagement = () => {
    window.location.href =
      process.env.REACT_APP_GIS_SERVICE + "/gismanagement?incidentId=22";
  };
  const refreshCardDetailHoriChart = async (
    title,
    apiKey,
    key,
    apiKey2,
    keyName
  ) => {
    setApiKeyName({
      apiKey: apiKey,
      apiKey2: apiKey2,
      keyName: keyName,
      key: key,
    });
    setHorizontalChartTitle(title);
    setDrildownAPIkey(key);
    let startDateStr = moment(fromDate).format("YYYY-MM-DD");
    let endDateStr = moment(toDate).format("YYYY-MM-DD");
    let data;
    let data2;

    if (activeTab === "dss") {
      data = await getData(
        getAffectedDrilldownView
          .replace("API_KEY", apiKey)
          .replace("FROMDATE", startDateStr)
          .replace("TODATE", endDateStr)
      );
      data2 = await postDataWithBody(
        getCurrBarChartData + apiKey2,
        {
          fromDate: startDateStr,
          toDate: endDateStr,
        },
        {}
      );
      setactiveKeyName(keyName);
    } else {
      data = await postDataWithBody(getCurrBarChartData + apiKey, {}, {});
      setactiveKeyName(keyName);
    }

    let customizedList = [
      "motor_boat",
      "private_boat",
      "government_boat",
      "dry_ration",
      "families_gr_distributed",
      "food_packets",
      "polythene_sheets",
      "health_centre",
      "community_kitchen",
      // "people_remaining_in_camps",
    ];

    if (customizedList.indexOf(key) > -1) {
      if (data && data.length > 0) {
        for (let dataElem in data) {
          data[dataElem]["count"] = data[dataElem][key];
        }
      } else {
        data = [];
      }
    }
    const filtered = data.filter((item) => item.count > 0);
    if (data2?.length > 0 && keyName) {
      const updatedData2 = data2
        .map((item) => ({
          ...item,
          count: item[keyName], // Rename the property to 'count'
        }))
        .filter((item) => item.count > 0);

      const result = aggregateData(updatedData2);
      setTrendData(result);
    } else if (data2?.length > 0) {
      const filtered2 = data2.filter((item) => item?.count > 0);
      const result = aggregateData(filtered2);
      setTrendData(result);
    } else {
      setTrendData([]);
    }
    setDrillDownData(filtered);
  };

  const refreshDashboard = async (startDateStr, endDateStr) => {
    startDateStr = moment(startDateStr).format("YYYY-MM-DD");
    endDateStr = moment(endDateStr).format("YYYY-MM-DD");
    setLoader(true);
    var data = await getData(
      getStateFloodCountData
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );
    setCardData(data[0]);
    data = data[0];
    if (data?.families_gr_distributed) {
      data.families_gr_distributed = `${Math.round(
        data.families_gr_distributed / 7000
      )} (Rs: ${data.families_gr_distributed})`;
    }
    data = await postDataWithBody(getCurrStateFloodCountData, {}, {});
    setCurrCardData(data[0]);
    let blockdata = [];
    data = await getData(
      getBlockWiseReport
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );
    data.forEach(function (data, index) {
      blockdata.push([data.count_date, data.block_count]);
    });

    // Fetch affected blocks data for Doughnut chart
    data = await getData(
      getAffectedBlock
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );
    setAffectedBlockData(data);

    // Fetch affected districts data for Doughnut chart
    data = await getData(
      getAffectedDistrict
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );

    setAffectedDistrictData(data);

    // Fetch affected NagarNigam data for Doughnut chart
    data = await getData(
      getAffectedNagarNigam
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );
    setAffectedNagarNigamData(data);

    // Fetch affected districts Panchayat for Doughnut chart
    data = await getData(
      getAffectedPanchayat
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );
    setAffectedPanchayatData(data);

    setLoader(false);
  };

  const refreshDashboardBtnClick = async () => {
    let startDateStr = moment(fromDate).format("YYYY-MM-DD");
    let endDateStr = moment(toDate).format("YYYY-MM-DD");
    setLoader(true);

    var data = await getData(
      getStateFloodCountData
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );

    setCardData(data[0]);
    data = data[0];
    data.families_gr_distributed = `${Math.round(
      data.families_gr_distributed / 7000
    )} (Rs: ${data.families_gr_distributed})`;
    data = await postDataWithBody(getCurrStateFloodCountData, {}, {});
    setCurrCardData(data[0]);

    if (activeTab === "dss") {
      let data = await getData(
        getAffectedDrilldownView
          .replace("API_KEY", apiKeyName.apiKey)
          .replace("FROMDATE", startDateStr)
          .replace("TODATE", endDateStr)
      );
      let data2 = await postDataWithBody(
        getCurrBarChartData + apiKeyName.apiKey2,
        {
          fromDate: startDateStr,
          toDate: endDateStr,
        },
        {}
      );
      let customizedList = [
        "motor_boat",
        "private_boat",
        "government_boat",
        "dry_ration",
        "families_gr_distributed",
        "food_packets",
        "polythene_sheets",
        "health_centre",
        "community_kitchen",
        "people_remaining_in_camps",
        "animal_shelter",
      ];

      if (customizedList.indexOf(apiKeyName.key) > -1) {
        if (data && data.length > 0) {
          for (let dataElem in data) {
            data[dataElem]["count"] = data[dataElem][apiKeyName.key];
          }
        } else {
          data = [];
        }
      }
      const filtered = data.filter((item) => {
        return item.count > 0;
      });
      if (data2?.length > 0 && apiKeyName.keyName) {
        const updatedData2 = data2
          .map((item) => ({
            ...item,
            count: item[apiKeyName.keyName], // Rename the property to 'count'
          }))
          .filter((item) => item.count > 0);
        const result = aggregateData(updatedData2);
        setTrendData(result);
      } else if (data2?.length > 0) {
        const filtered2 = data2.filter((item) => item?.count > 0);
        const result = aggregateData(filtered2);
        setTrendData(result);
      } else {
        setTrendData([]);
      }
      setDrillDownData(filtered);
    }

    let blockdata = [];
    data = await getData(
      getBlockWiseReport
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );
    data.forEach(function (data, index) {
      blockdata.push([data.count_date, data.block_count]);
    });

    // Fetch affected blocks data for Doughnut chart
    data = await getData(
      getAffectedBlock
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );
    setAffectedBlockData(data);

    // Fetch affected districts data for Doughnut chart
    data = await getData(
      getAffectedDistrict
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );

    setAffectedDistrictData(data);

    // Fetch affected NagarNigam data for Doughnut chart
    data = await getData(
      getAffectedNagarNigam
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );
    setAffectedNagarNigamData(data);

    // Fetch affected districts Panchayat for Doughnut chart
    data = await getData(
      getAffectedPanchayat
        .replace("FROMDATE", startDateStr)
        .replace("TODATE", endDateStr)
    );
    setAffectedPanchayatData(data);

    setLoader(false);
  };

  function getTrendChart() {
    return (
      <FamilyAffectedTrendView
        startDateStr={moment(fromDate).format("YYYY-MM-DD")}
        endDateStr={moment(toDate).format("YYYY-MM-DD")}
        chartData={trendData}
        drillDownKey={drildownAPIkey}
        data={drillDownData}
        title={horizontalChartTitle}
        activeTab={activeTab}
        activeKeyName={activeKeyName}
      />
    );
  }

  const dssDefaultApiCall = async () => {
    let startDate = moment(fromDate).format("YYYY-MM-DD");
    let endDate = moment(toDate).format("YYYY-MM-DD");
    setDrildownAPIkey("population_affected");
    setApiKeyName({
      apiKey: "getPopAffectedDetails",
      apiKey2: "trnd_population_affected",
    });

    const data = await getData(
      getAffectedDrilldownView
        .replace("API_KEY", "getPopAffectedDetails")
        .replace("FROMDATE", startDate)
        .replace("TODATE", endDate)
    );
    const data2 = await postDataWithBody(
      getCurrBarChartData + "trnd_population_affected",
      {
        fromDate: startDate,
        toDate: endDate,
      },
      {}
    );
    const filtered = data?.filter((item) => item.count > 0);
    const filtered2 = data2?.filter((item) => item.count > 0);
    setDrillDownData(filtered);
    setTrendData(filtered2);
  };
  const currDefaultApiCall = async () => {
    setApiKeyName({
      apiKey: "curr_fs_blocks_affected",
    });
    setDrildownAPIkey("curr_dss_affected_blocks");
    const data = await postDataWithBody(
      getCurrBarChartData + "curr_fs_blocks_affected",
      {},
      {}
    );
    const filtered = data?.filter((item) => item?.count > 0);
    setDrillDownData(filtered);
  };
  const exportTooltip = (
    <Tooltip
      id="export-tooltip"
      style={{
        // whiteSpace: "nowrap",
        zIndex: 1050,
        transform: "translateY(10px)", // Push the tooltip down
        overflow: "visible",
      }}
    >
      <span>
        {" "}
        Select proper <strong>From Date</strong> and <strong>To Date</strong>{" "}
        <br />
      </span>
    </Tooltip>
  );

  useEffect(() => {
    if (activeTab === "dss") {
      dssDefaultApiCall();
      setClickedKey("population_affected");
      setHorizontalChartTitle("Population Affected");
      setFromDate(process.env.REACT_APP_FLD_DASHBOARD_START_DATE);
      setToDate(new Date());
      refreshDashboard(
        process.env.REACT_APP_FLD_DASHBOARD_START_DATE,
        new Date()
      );
    } else if (activeTab === "currentStatus") {
      setClickedKey("curr_dss_affected_blocks");
      currDefaultApiCall();
      setHorizontalChartTitle("Affected Blocks");
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setFromDate(yesterday);
      setToDate(yesterday);
      refreshDashboard(yesterday, yesterday);
    }
  }, [activeTab, t]);

  const toggleFullscreen = () => {
    if (isFullscreen) {
      handle.exit();
    } else {
      handle.enter();
    }
    setIsFullscreen(!isFullscreen);
  };
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 6,
      height: 8,
      id: 6,
      component: getTrendChart(),
    },
    {
      x: 6,
      y: 0,
      width: 3,
      height: 4,
      id: 7,
      component: (
        <DoughnutChart
          title={t("flooddisaster.doughnutchart1")}
          dataval={affectedDistrictData}
          startDateStr={moment(fromDate).format("YYYY-MM-DD")}
          endDateStr={moment(toDate).format("YYYY-MM-DD")}
          partial={true}
          showViewIcon={true}
        />
      ),
    },
    {
      x: 9,
      y: 0,
      width: 3,
      height: 4,
      id: 8,
      component: (
        <HalfDoughnutChart
          startDateStr={moment(fromDate).format("YYYY-MM-DD")}
          endDateStr={moment(toDate).format("YYYY-MM-DD")}
          title={t("flooddisaster.doughnutchart2")}
          dataval={affectedBlockData}
          partial={true}
          showViewIcon={true}
        />
      ),
    },
    {
      x: 6,
      y: 4,
      width: 3,
      height: 4,
      id: 10,
      component: (
        <HorizontalBarChart
          title={t("flooddisaster.doughnutchart3")}
          dataval={affectedNagarNigamData}
          showAffectedCount={true}
        />
      ),
    },
    {
      x: 9,
      y: 4,
      width: 3,
      height: 4,
      id: 12,
      component: (
        <HorizontalBarChart
          title={t("flooddisaster.doughnutchart4")}
          dataval={affectedPanchayatData}
          showAffectedCount={true}
        />
      ),
    },
    // {
    //   x: 6,
    //   y: 0,
    //   width: 6,
    //   height: 8,
    //   id: 6,
    //   component: <WeatherDataTable />,
    // },
  ];
  const layoutDataBmsk = [
    {
      x: 0,
      y: 0,
      width: 6,
      height: 11,
      id: 6,
      component: getTrendChart(),
    },
    {
      x: 6,
      y: 0,
      width: 6,
      height: 11,
      id: 6,
      component: (
        <WeatherDataTable
          affectedDistrictData={affectedDistrictData}
          affectedBlockData={affectedBlockData}
          affectedNagarNigamData={affectedNagarNigamData}
          affectedPanchayatData={affectedPanchayatData}
          fromDate={fromDate}
          toDate={toDate}
        />
      ),
    },
  ];
  const layoutData1 = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 8,
      id: 6,
      component: getTrendChart(),
    },
  ];

  const girdoptions = {
    staticGrid: true,
    column: 24,
    margin: 5,
  };
  useLayoutEffect(() => {
    var grid = GridStack.init(girdoptions);
  }, []);

  console.log("activeTab:", activeTab); // Log the value of activeTab
  console.log("activeTabBmsk:", activeTabBmsk); // Log the value of activeTabBmsk
  const layoutToDisplay = (() => {
    if (activeTab === "currentStatus") {
      return layoutData1;
    } else if (activeTabBmsk === "BMSK") {
      return layoutDataBmsk;
    } else if (activeTabBmsk === "Charts" || activeTab === "dss") {
      return layoutData;
    } else {
      return layoutData; // Default layout
    }
  })();

  const externalStyle1 = {
    multiselectContainer: {
      minWidth: "6rem",
      height: "1.5rem", // Increase height slightly for better balance
      backgroundColor: "#f0f0f0",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center", // Center vertically
      justifyContent: "space-between", // Balance text and arrow
      padding: "0 0.5rem", // Add spacing inside the container
      boxSizing: "border-box",
      color: "#000",
      fontSize: "14px", // Adjust font size for better proportion
      lineHeight: "1", // Ensures text height is balanced
    },
    optionContainer: {
      // border: "1px solid #ccc",
      backgroundColor: "#fff",
      color: "#000",
      maxHeight: "200px",
      overflowY: "auto",
    },
  };
  

  const handleYearSelection = (selectedList) => {
    if (selectedList.length > 0) {
      const year = parseInt(selectedList[0].name, 10); // Get the selected year
      setSelectedYear(year);
  
      // Set fromDate to June 1st of the selected year
      const fromDate = new Date(year, 5, 1); // June 1st
      
      // Get today's date
      const today = new Date();
      
      // Set toDate to today if today is between June 1st and October 31st of the selected year
      const toDate = (today >= fromDate && today <= new Date(year, 9, 31)) ? today : new Date(year, 9, 31); // October 31st
      
      setFromDate(fromDate);
      setToDate(toDate);
    }
  };
  

  useEffect(() => {
    // Set initial state on component load for 2024
    handleYearSelection([{ name: "2025" }]);
  }, []);

  useEffect(() => {
    if (fromDate && toDate && fromDate > toDate) {
      setError( "Select proper From Date and To Date");
    } else {
      setError(null);
    }
  }, [fromDate, toDate]);

  return (
    <FullScreen handle={handle}>
      <div
        className={
          isFullscreen
            ? "state-dashboard_div fullscreen"
            : "state-dashboard_div"
        }
        style={{
          height: isFullscreen ? "100vh" : "auto", // Full height when fullscreen, else auto height
          overflow: isFullscreen ? "auto" : "initial", // Enable scrolling in fullscreen mode
          padding: "10px",
        }}
      >
        <Loader
          show={loader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
        {activeTab === "dss" && (
          <div
            style={{
              // backgroundColor: "#252b48",
              marginLeft: "15px",
              width: "97%",
              // margintop: "10px",
              // marginBottom: "10px",
              // padding:"8pt",
              // height: "55px",
              marginLeft: "20px",
              borderRadius: "8px",
              fontSize: "20px",
            }}
          >
            DSS Dashboard
          </div>
        )}
        <div className="container-fluid">
          <div className="toprow--container">
            <div className="dash-title"></div>
            {activeTab === "currentStatus" && (
              <div
                style={{
                  // backgroundColor: "#252b48",
                  marginLeft: "-30px",
                  // width: "97%",
                  // margintop: "10px",
                  // marginBottom: "10px",
                  // padding:"8pt",
                  // height: "55px",
                  // marginLeft: "20px",
                  borderRadius: "8px",
                  fontSize: "20px",
                }}
              >
                DSS Dashboard
              </div>
            )}

            <TabSwitch setActiveTab={setActiveTab} activeTab={activeTab} />

            <>
              {activeTab === "dss" && (
                <div
                  className="single-multiselect"
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <label
                    htmlFor="entryType"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "White",
                      marginLeft: "15px",
                    }}
                  >
                    F.Y.
                  </label>
                  <Multiselect
                    id="entryType"
                    style={{ ...externalStyle1}}
                    className="custom-multiselect"
                     // Assuming externalStyle1 is an object
                    options={[{ name: "2024" }, { name: "2025" }]} // Static options
                    displayValue="name"
                    customArrow={true}
                    placeholder="Select Fiscal Year"
                    singleSelect={true}
                    closeOnSelect={true}
                    onSelect={handleYearSelection} // Calling the handler on selection
                    selectedValues={
                      selectedYear ? [{ name: selectedYear.toString() }] : [] // Show the selected value if available
                    }
                    customCloseIcon={true}
                  />
                </div>
              )}
            </>
            <div className="d-flex gap-3">
              <div className="date-picker-container">
                <span className="ms-1">{t("flooddisaster.datetxtfrom")}</span>
                <ReactDatePicker
                  selected={fromDate}
                  onChange={setFromDate}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  minDate={new Date(selectedYear, 5, 1)} // Dynamic minDate
                  maxDate={new Date(selectedYear, 9, 31)} // Dynamic maxDate
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                />
              </div>

              <div className="date-picker-container">
                <span>{t("flooddisaster.datetxtto")}</span>
                <ReactDatePicker
                  selected={toDate}
                  onChange={setToDate}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(selectedYear, 5, 1)} // Dynamic minDate
                  maxDate={new Date(selectedYear, 9, 31)} // Dynamic maxDate
                  isClearable
                  disabled={activeTab === "currentStatus"}
                  placeholderText={t("flooddisaster.dateplaceholder")}
                  className="custom-datepicker ms-2"
                />
              </div>
              <div>
                {activeTab === "dss" &&
                  // (error ? (
                  //   <OverlayTrigger placement="top" overlay={exportTooltip}>
                  //     <LMSButton
                  //       size="btn_sm"
                  //       label={t("flooddisaster.apply")}
                  //       onClick={refreshDashboardBtnClick}
                  //       disabled={!!error}
                  //       // className="apply-btn"
                  //     />
                  //   </OverlayTrigger>
                  // ) : (
                    <LMSButton
                      size="btn_sm"
                      label={t("flooddisaster.apply")}
                      onClick={refreshDashboardBtnClick}
                      disabled={!!error}
                      // className="apply-btn"
                    />
                  }
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center">
              <div className="action-buttons d-flex gap-2">
                <LMSButton
                  size="btn_sm"
                  label="Form 1-Status"
                  onClick={() => {
                    navigate("/dist-dashboard/reporting-status");
                  }}
                />

                <LMSButton
                  size="btn_sm"
                  label={
                    <span>
                      <MapIcon /> {t("flooddisaster.gis_nav_btn_lbl")}{" "}
                    </span>
                  }
                  onClick={navigateToGisManagement}
                />
                <span onClick={toggleFullscreen} className="cursor-pointer">
                  <Expand
                    fill="var(--white3-color)"
                    width="1.1em"
                    height="1.1em"
                  />
                </span>
              </div>
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          {activeTab === "dss" ? (
            <div className="dashboard_card-container mt-2">
              {dashboardCardConfig?.map((card, index) => (
                <DashboardCard
                  key={index}
                  config={card}
                  clickedKey={clickedKey}
                  setClickedKey={setClickedKey}
                  data={cardData}
                  triggeredFunction={refreshCardDetailHoriChart}
                />
              ))}
            </div>
          ) : (
            <div className="curr-dashboard_card">
              <div className="px-3 pt-3">Current Status Details</div>
              <div className="curr-dashboard_card-container mt-2">
                <CurrentDashboardCard
                  config={currentCardConfig}
                  clickedKey={clickedKey}
                  setClickedKey={setClickedKey}
                  data={currCardData}
                  triggeredFunction={refreshCardDetailHoriChart}
                />
              </div>
            </div>
          )}
          <div></div>
          <div>
            {/* <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "end" }}>
    <ToggleViewBmsk setActiveTabBmsk={setActiveTabBmsk} activeTabBmsk={activeTabBmsk} />
  </div> */}
            <div
              className="grid-stack grid-stack-instance-1 mt-1"
              style={{
                overflowY: "auto", // Enable vertical scrolling
                maxHeight: isFullscreen ? "calc(100vh - 120px)" : "initial", // Adjust height based on fullscreen
              }}
            >
              {layoutToDisplay.map((gridItem) => (
                <div
                  className="grid-stack-item"
                  gs-x={gridItem.x}
                  gs-y={gridItem.y}
                  gs-w={gridItem.width}
                  gs-h={gridItem.height}
                  key={gridItem.id}
                >
                  <div
                    className={`grid-stack-item-content ${
                      gridItem?.haveNoBg ? " " : " grid-item"
                    } ${
                      gridItem?.haveOverflowVisible
                        ? " apply__overflow--visible"
                        : ""
                    }`}
                  >
                    {gridItem.component}
                  </div>
                </div>
              ))}
            </div>
          </div>
          

        </div>
      </div>
    </FullScreen>
  );
};
export default StateDashboard;
