import React, { useState, useEffect, useContext } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import  "./DailyColdWaveRegForm.css";
import { useKeycloak } from "@react-keycloak/web";
import { toast, ToastContainer } from "react-toastify";
import {
  postDataWithBody,
  getData,
  
  putDataWithBody,
  postDataWithBodyFrGIS,
} from "../../../../Services/Services";
import {
  getUsersFromApp,
  getAllRole,
  createTaskandAssign,
  createNewTask,
  updateColdWaveDetails
} from "../../../../Services/EndPoints";
import { yupResolver } from "@hookform/resolvers/yup";
import { DailyColdWaveFormConfig, schema } from "../../../../JsonData/DailyColdWaveConfig";
import moment from "moment";
import { RoleContext } from "../../../..";

const DailyColdWaveRegistrationForm = () => {
    const { roleName } = useContext(RoleContext);
  const { keycloak } = useKeycloak();
  const [activeTab, setActiveTab] = useState(0);
  const [loader, setLoader] = useState(false);
//   const [dailyColdWaveFormConfig, setDailyColdWaveFormConfig] = useState(
//     dailyColdWaveFormConfig
//   );
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const {isTextFieldDisable } = location.state || {};
  let dataFromTable = state?.data;

  let date = state?.date;

  let districtNameValue = state?.districtNameValue;

  console.log("COLDWAVEDATA",dataFromTable);
  console.log("COLDWAVEDATE",date);
  console.log("DistrictNameValue",districtNameValue);

  const [lgShow, setLgShow] = useState(false);

 
  const {
    register,
    handleSubmit,
    setValue,
    getValues,

    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema[activeTab]),
    mode: "all",
  });
  const assign_type = useWatch({
    name: "assign_type",
    control,
  });
  

//   useEffect(() => {
//     document.getElementById("container_user_list").classList.add('disabled_dropdown');
//     getUserList();
//     fetchAllRoles();
//     if(data){
//       document.getElementById("submitBtn").style.display="none";
//       reset(data);
//       console.log(data,"data.......................")
//     }
//   }, []);

 
  
//   const getUserList = async () => {
//     setLoader(true);
//     var userIdNameMap = {};
//     var userList = await getData(getUsersFromApp);
//     (userList).sort((a, b) => (a.name > b.name) ? 1: -1);
//     let userArrSanitized = [];
//     userList.forEach((user, index) => {
//       userIdNameMap[user.id] = user.first_name+" "+user.last_name;
//       userArrSanitized.push({
//         label: user.first_name+" "+user.last_name,
//         value: user.id,
//       });
//     });
//     TaskMgntFormConfig[0].fields[5]["options"] = userArrSanitized;
//     setTaskMgntFormConfig(TaskMgntFormConfig);
//     populateUserList(userIdNameMap);
//   };

//   const populateUserList = (userIdNameMap)=>{
//     let userListToPrePopulate = [];
//     if(data){
//       var userListFrmEdit = data.user_list.split(",");
//       for(let user in userIdNameMap){
//         if(userListFrmEdit.indexOf(user)>-1){
//           userListToPrePopulate.push({"label":userIdNameMap[user],"value":user});
//         }
//       }
//       setValue("user_list",userListToPrePopulate);
//     }
//   }
//   const populateRoleList = (roleIdNameMap)=>{
//     let roleListToPrePopulate = [];
//     if(data){
//       var roleListFrmEdit = data.role_list.split(",");
//       for(let role in roleIdNameMap){
//         if(roleListFrmEdit.indexOf(role)>-1){
//           roleListToPrePopulate.push({"label":roleIdNameMap[role],"value":role});
//         }
//       }
//       setValue("role_list",roleListToPrePopulate);
//       console.log(roleListToPrePopulate,"..........")
//     }
//   }
//   const fetchAllRoles = async () => {
//     setLoader(true);
//     let rolearr = await getData(getAllRole);
//     (rolearr).sort((a, b) => (a.name > b.name) ? 1: -1);
//     let roleArrSanitized = [];
//     var roleIdNameMap = {};
//     rolearr.forEach((role, index) => {
//       roleIdNameMap[role.id] = role.name;
//       roleArrSanitized.push({
//         label: role.name,
//         value: role.id,
//       });
//     });
//     TaskMgntFormConfig[0].fields[4]["options"] = roleArrSanitized;
//     setTaskMgntFormConfig(TaskMgntFormConfig);
//     //setValue("role_list", rolearr);
//     setLoader(false);
//     populateRoleList(roleIdNameMap);
//   };
//   if(assign_type && assign_type == 1){
//     document.getElementById("container_user_list").classList.remove('disabled_dropdown');
//     document.getElementById("container_role_list").classList.add('disabled_dropdown');
//     document.getElementById("assign_task").classList.add('disabled_dropdown');

//     setValue("role_list", []);
//     setValue("assign_task", "")
//   }
//   else if(assign_type && assign_type == 0){
//     document.getElementById("container_user_list").classList.add('disabled_dropdown');
//     document.getElementById("container_role_list").classList.remove('disabled_dropdown');
//     document.getElementById("assign_task").classList.remove('disabled_dropdown');
//     setValue("user_list",[]);
//     // setValue("assign_task")
//   }


 // Reset form with the provided data

 useEffect(() => {
    // const formattedDate = moment(date).format("DD-MM-YYYY");
    // console.log("FORMATTEDDate",formattedDate);

    if (dataFromTable) {
      reset({
        date: date,
        district_name: districtNameValue,
        block_name: dataFromTable?.block_name,
        number_of_bonfire_places_reporting: dataFromTable?.number_of_bonfire_places,
        number_of_bonfire_places_total: dataFromTable?.number_of_bonfire_places_so_far,
        quantity_wood_burnt_reporting: dataFromTable?.quantity_of_wood_burnt,
        quantity_wood_burnt_total: dataFromTable?.quantity_of_wood_burnt_so_far,
        population_affected_reporting: dataFromTable?.pop_affected_by_cold_wave,
        population_affected_total: dataFromTable?.pop_affected_by_cold_wave_so_far,
        deaths_reporting: dataFromTable?.number_of_deaths,
        deaths_total: dataFromTable?.number_of_deaths_so_far,
        amount_allocated: dataFromTable?.amount_allocated_by_department ? dataFromTable?.amount_allocated_by_department : "",
        amount_spent_updated: dataFromTable?.updated_amount_spent,
        night_shelters_reporting: dataFromTable?.number_of_night_shelters,
        night_shelters_total: dataFromTable?.number_of_night_shelters_so_far,
        people_in_shelter_reporting: dataFromTable?.number_of_ppl_taking_shelter_in_night_shelters,
        people_in_shelter_total: dataFromTable?.number_of_ppl_taking_shelter_in_night_shelters_so_far,
        blankets_distributed_reporting: dataFromTable?.number_of_blankets_distributed,
        blankets_distributed_total: dataFromTable?.number_of_blankets_distributed_so_far,
        remarks: dataFromTable?.remarks || "", // Ensure remarks are included
      });
    }
  }, [dataFromTable, reset, date]);
  

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        
          <FormHandler
            register={register}
            errors={errors}
            fields={fields}
            control={control}
            columns={2}
            getValues={getValues}
            isTextFieldDisable={isTextFieldDisable}
            roleName={roleName}
          />
     
      </div>
    );
  };

  

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  
  const submitHandler = async (data, e) => {
    e.preventDefault(); // Prevent default form submission

    console.log("COLDeidtformdata",data);
  
    const formData = new FormData();
  

  
    setLoader(true); // Show loader
  
    const input = {
        "id":dataFromTable?.chaild_id,
        "number_of_bonfire_places": data?.number_of_bonfire_places_reporting,
        "quantity_of_wood_burnt": data?.quantity_wood_burnt_reporting,
        "pop_affected_by_cold_wave": data?.population_affected_reporting,
        "number_of_deaths": data?.deaths_reporting,
        "amount_allocated_by_department": data?.amount_allocated ? Number(data?.amount_allocated) : 0, // Ensure it's a number or default to 0
        "updated_amount_spent": data?.amount_spent_updated,
        "number_of_night_shelters": data?.night_shelters_reporting,
        "number_of_ppl_taking_shelter_in_night_shelters": data?.people_in_shelter_reporting,
        "number_of_blankets_distributed": data?.blankets_distributed_reporting,
        "remarks": data?.remarks,
      };
  
      const res = await postDataWithBody(
        updateColdWaveDetails.replace("DATE", date),
        input,
        {}
      );
    if (res.status === "success") {
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setLoader(false);
      navigate("/dist-dashboard/dr-cold-wave");
    } else {
      toast.error(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setLoader(false);
    }
  };
  
  

  const tabsData = generateTabsData(DailyColdWaveFormConfig);
  return (
    <div className="mt-4 facility-container">
    

      <div className="form-body">
        <div className="registration__form--body">
          <form
            onSubmit={handleSubmit(submitHandler)}
            name="CreateFacilityRegForm"
          >
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" id="submitBtn" className="mx-2" variant="primary">
                  Update
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
      
    </div>
  );
};

export default DailyColdWaveRegistrationForm;
