import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Loader from "react-spinner-loader";
import {
  Editicon,
  Deleteicon,
  Viewicon,
} from "../../../../Components/IconComponents";
import Table from "../../../../Components/UiComponents/Table/Table";
//import { Modal, Button } from "react-bootstrap";
import {
  contactTableData,
  userTableDatacolumnsForExport,
} from "../../../../JsonData/ContactConfig";
import {
  getUsersFromApp,
  deleteContactURL,
  deleteUserById,
} from "../../../../Services/EndPoints";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { getData, putDataWithBody } from "../../../../Services/Services";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import { useTranslation } from "react-i18next";
import { RoleContext } from "../../../..";
import Moment from "react-moment";
const UserTable = forwardRef((props, ref) => {
  const { roleName } = useContext(RoleContext);
  const dispatch = useDispatch();
  const qs = require("qs");
  const [userData, setUserData] = useState(contactTableData);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const { t, i18n } = useTranslation();
  const [searchData, setSearchData] = useState([]);


  const formatSearchData = (data) => {
    return data.map(role => {
        // Create a string of comma-separated permission names
        const formattedUserRole = role.user_role_map.map(obj => {
            return obj.roleEntity.name;
        }).join(", "); // Join the permission names with a comma and space

        // Return the role with formatted permissions as a string
        return {
            ...role,
            user_role_map: formattedUserRole, // Replace array with the formatted string
        };
    });
};

  console.log(searchData,"searchuserdata");
  useEffect(() => {
    const formattedData = formatSearchData(searchData);
    console.log(formattedData,"formatedUserroles");
    sendDataToCentralStore(formattedData);
  }, [searchData]);

  useEffect(() => {
    getUserList();
  }, []);
  const addNewHandler = () => {
    navigate("/users/create-user");
  };

  const getShortenedText = (strLength, fulltext) => {
    let abbreviatedText = "";
    if (fulltext.length > strLength) {
      abbreviatedText = fulltext.substring(0, 15) + "...";
    } else {
      abbreviatedText = fulltext;
    }
    return <span title={fulltext}>{abbreviatedText}</span>;
  };
  const getUserList = async () => {
    setLoader(true);
    var userList = await getData(getUsersFromApp);
    setLoader(false);
    userList.forEach(value => {
      // user_role_map
      value.user_role_map_forSearch = value.user_role_map?.map(role => role.roleEntity.name).join(", ") || "NA";
    })
    console.log('ssss',userList);
    setUserData(userList);
    setRowCount(userList.length);
  };
  //   useEffect(() => {
  //     if (props.locationInstance && props.locationInstance.msg != "") {
  //       toast.success("Agency has been created successfully.", {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //     }
  //     getAgecnyList();
  //   }, []);

  const sendDataToCentralStore = (data) => {
    console.log(data, "USERDATA");
    if (data.length > 0) {
      const columns = userTableDatacolumnsForExport;
  
      const processedData = data.map((item) => ({
        ...item,
        full_name: `${item.first_name || ""} ${item.last_name || ""}`.trim(),
        // Format the createdOn date as DD/MM/YYYY, HH:mm
        createdOn: item.createdOn
          ? new Date(item.createdOn).toLocaleDateString('en-GB') + ', ' + new Date(item.createdOn).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
          : "NA",
      }));
  
      console.log("PRdataForuser",processedData);
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, processedData),
        })
      );
    }
  };
  
  
  
  //   const navToCreatAgency = (parentAgencyId) => {
  //     navigate("/agencylist/createagency", {
  //       state: { parentAgencyId: parentAgencyId },
  //     });
  //   };
  //   const getAgecnyList = async () => {
  //     var agencyList = await getData(agencyListURL);
  //     setAgencyData(agencyList);
  //     sendDataToCentralStore(agencyList);
  //   };

  //   Name:"",
  //   Phone1:"",
  //   Phone2:"",
  //   Email:"",
  //   Address:"",
  //   ContactType:"",
  //   Agency:"",

  const columns = React.useMemo(
    () => [
      {
        Header: t("usertableheaders.name"),
        Cell: (props) => {
          var userObj = props.row.original;
          let fullName = userObj.first_name + " " + userObj.last_name;
          return getShortenedText(15, fullName);
        },
        disableSortBy: true,
      },
      {
        Header: t("usertableheaders.username"),
        accessor: "user_name",
        disableSortBy: true,
      },
      {
        Header: t("usertableheaders.phone"),
        accessor: "phone_number",
        disableSortBy: true,
      },
      {
        Header: t("Created On"),
        accessor: "createdOn",
        disableSortBy: true,
        width: 180,  // Adjust the width for the "Created On" column
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
      },
      {
        Header: t("usertableheaders.secondaryphone"),
        accessor: "secondary_phone_number",
        disableSortBy: true,
        Cell: (props) => {
          return props.value
            ? props.value === null
              ? "NA"
              : props.value
            : "NA";
        },
      },

      {
        Header: t("usertableheaders.role"),
        accessor: "user_role_map_forSearch",
        Cell: (props) =>  {
          return props.value
            ? props.value === null
              ? "NA"
              : props.value
            : "NA";
        },
        disableSortBy: true,
      },

      {
        Header: t("usertableheaders.action"),
        disableSortBy: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteUserById, data, {});
            if (res.status == "success") {
              getUserList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            setIsdelete(false);
          };
          return (
            <div className="usertable__actionsicon">
              <span
                onClick={() =>
                  navigate("/users/create-user", {
                    state: {
                      data: props.row.original,
                    },
                  })
                }>
                <Editicon value={props.value} className="cursor-pointer ms-3" />
              </span>
              {roleName === process.env.REACT_APP_SUPER_ADMIN && (
                <span onClick={deletePopUp} className="mx-2">
                  <Deleteicon
                    value={props.value}
                    className="cursor-pointer mr-1"
                  />
                </span>
              )}
              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.name}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t, roleName]
  );

  console.log(userData,"userdata");
  return (
    <div className="create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        {contactTableData && (
          <Table
            data={userData}
            columns={columns}
            numberOfRows={10}
            setSearchData={setSearchData}
            addButtonLabel={t("createuseraddbuttonlabel")}
            addNewHandler={addNewHandler}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              borderRadius: "12px",
            }}
          />
        )}
      </div>
    </div>
  );
});

export default UserTable;
