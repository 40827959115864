import React from "react";
import "./ResourceTable.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Editicon,
  Deleteicon,
  Viewicon,
} from "../../../../../Components/IconComponents";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import Table from "../../../../../Components/UiComponents/Table/Table";
import { Modal, Button } from "react-bootstrap";
import Moment from "react-moment";
import { resourcemgmtcolumnsForExport } from "../../../../../JsonData/ResourceManagementConfig";
import { getData, putDataWithBody } from "../../../../../Services/Services";
import { setTableData } from "../../../../../CentralStore/ExportLMSTable/dataSlice";
import { getExportedColumnsData } from "../../../../../Components/UiComponents/utils";
import {
  resourceListURL,
  deleteResourcebyId,
  resourceCatListURL,
  resourceTypListURL,
  resourceActivityListURL,
} from "../../../../../Services/EndPoints";
import SelectComponent from "../../../../../Components/UiComponents/SelectComponent/SelectComponent";
import Confirmpopup from "../../../Common/ConfirmPopup/Confirmpopup";
import { useDispatch } from "react-redux";
import TextEllipsis from "../../../../../Components/UiComponents/TextEllipsis/TextEllipsis";
import Loader from "react-spinner-loader";

const ResourceTable = forwardRef((props, ref) => {
  const [loader, setLoader] = useState(false);
  const qs = require("qs");
  const [resourceData, setResourceData] = useState([]);
  const [resourceSrchdData, setResourceSrchdData] = useState(null);
  const [resourceCategoryData, setResourceCategoryData] = useState([]);
  const [resourceTypeData, setResourceTypeData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  useEffect(() => {
    getResourceList();
    getRsourceTypeList();
    if (props.locationInstance && props.locationInstance.msg != "") {
      toast.success(props.locationInstance.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
  }, []);
  const addNewHandler = () => {
    navigate("/resource-list/create-resource");
  };

  const getResourceList = async () => {
    setLoader(true);
    var resourceList = await getData(resourceListURL);
    setLoader(false);
    setResourceData(resourceList);
    setRowCount(resourceList.length);
    // sendDataToCentralStore(resourceList);
  };
  const searchByRsrcCategory = (event) => {
    var select_id = document.getElementById("resrcCatDropdown");
    let searchString =
      select_id.options[select_id.selectedIndex].text.toLowerCase();
    if (searchString != "all") {
      let rsrcCatArr = [];
      for (let resource in resourceData) {
        let actualString = resourceData[resource].category.toLowerCase();
        if (actualString.indexOf(searchString) > -1) {
          rsrcCatArr.push(resourceData[resource]);
        }
      }
      setResourceSrchdData(rsrcCatArr);
    } else {
      setResourceSrchdData(resourceData);
    }
  };
  const searchRsrcByType = (event) => {
    getRsourceCategoryList(event.target.value);
  };
  const getRsourceCategoryList = async (activity) => {
    var resourceCategoryList = await getData(resourceCatListURL + activity);
    for (let resourceCat in resourceCategoryList) {
      resourceCategoryList[resourceCat]["label"] =
        resourceCategoryList[resourceCat]["category"];
      resourceCategoryList[resourceCat]["value"] =
        resourceCategoryList[resourceCat]["category"];
    }
    setResourceCategoryData(resourceCategoryList);
  };

  const getRsourceTypeList = async () => {
    var resourceActList = await getData(resourceActivityListURL);
    for (let resource in resourceActList) {
      resourceActList[resource]["label"] =
        resourceActList[resource]["activity"];
      resourceActList[resource]["value"] =
        resourceActList[resource]["activity"];
    }
    //resourceActList.unshift({ id: "All", value: "ALL" });
    setResourceTypeData(resourceActList);
    getRsourceCategoryList(resourceActList[0]["activity"]);
  };

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = resourcemgmtcolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: t("resourcetableheaders.resourcename"),
        accessor: "resource_name",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 15) {
              descriptionText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
      },
      {
        Header: t("resourcetableheaders.resourceactivity"),
        accessor: "rsrc_activity_id",
        disableSortBy: true,
      },
      {
        Header: t("resourcetableheaders.resourcecategory"),
        accessor: "rsrc_cat_id",
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 15) {
              descriptionText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },

      {
        Header: t("resourcetableheaders.agencyname"),
        accessor: "agency_name",
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 15) {
              descriptionText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("resourcetableheaders.regionname"),
        accessor: "region_name",
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 15) {
              descriptionText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      // {
      //   Header: t("resourcetableheaders.createdon"),
      //   accessor: "created_on",
      //   disableSortBy: false, // Enable sorting
      //   sortType: (rowA, rowB, columnId) => {
      //     const dateA = new Date(rowA.values[columnId]);
      //     const dateB = new Date(rowB.values[columnId]);
      //     return dateB - dateA; // Newest first
      //   },
      //   Cell: (props) => {
      //     if (props && props.value) {
      //       return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
      //     } else {
      //       return "NA";
      //     }
      //   },
      // },

      {
        Header: t("resourcetableheaders.facility"),
        accessor: "facility",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 15) {
              descriptionText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     return props.value === true ? "Active" : "Inactive";
      //   },
      // },
      {
        Header: t("resourcetableheaders.action"),
        disableSortBy: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteResourcebyId, data, {});
            if (res.status == "success") {
              getResourceList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };

          return (
            <div className="usertable__actionsicon">
              <span
                className="resource__table--icons"
                onClick={() =>
                  navigate("/resource-list/create-resource", {
                    state: {
                      data: props.row.original.id,
                    },
                  })
                }>
                <Editicon className="cursor-pointer" mr-1 value={props.value} />
              </span>

              <span onClick={deletePopUp}>
                <Deleteicon
                  value={props.value}
                  className="cursor-pointer mr-1"
                />
              </span>

              {isdelete && (
                <Confirmpopup
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={""}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t]
  );
  return (
    <div>
      <div className="create-user">
        <Loader
          show={loader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
        <div className="datatable__container">
          <div className="ps-3 w-50 searchRsrcContainer d-none d-flex float-start position-relative">
            <SelectComponent
              id="resrcTypeDropdown"
              classList={"me-2"}
              onChange={searchRsrcByType}
              options={resourceTypeData}
            />

            <SelectComponent
              onChange={searchByRsrcCategory}
              id="resrcCatDropdown"
              options={resourceCategoryData}
            />
          </div>

          {resourceData && (
            <Table
              data={
                resourceSrchdData != null ? resourceSrchdData : resourceData
              }
              addButtonLabel={t("resourceaddButtonLabel")}
              addNewHandler={addNewHandler}
              columns={columns}
              numberOfRows={10}
              showExport={false}
              setSearchData={setSearchData}
              showFilter={false}
              showReset={false}
              onExpand={false}
              rowCount={rowCount} // Pass rowCount to the Table component
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                borderRadius: "12px",
              }}
            />
          )}
        </div>

        {/* <Modal show={isShow}>
                <Modal.Header closeButton onClick={hideModal}>
                    <Modal.Title>Alert !</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete the role ?</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className="delete_user_btn"
                        // onClick={hideModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        // onClick={deleteRoleByRoleId}
                        variant="dark"
                        className="create_user_btn"
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal> */}
      </div>
    </div>
  );
});

export default ResourceTable;
