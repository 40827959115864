import * as yup from "yup";

export const usertaskmgntschema = [
  yup.object({
    name: yup.string().required("Please provide the name"),
    assign_type: yup.string().required("Please select an assign type"),
    assign_task: yup
      .string()
      .when("assign_type", {
        is: (assign_type) => assign_type !== "1", // Only validate if assign_type is not "1"
        then: yup
          .string()
          .notOneOf([""], "Please select assign task") // Validate against "Select Option"
          .required("Please select assign task"),
        otherwise: yup.string(), // No validation if assign_type is "1"
      }),
    role_list: yup
      .array()
      .when("assign_type", {
        is: "0", // Only validate role_list if assign_type is "1"
        then: yup.array().min(1, "At least one role must be selected").required("Role list is required"),
        otherwise: yup.array(), // No validation if assign_type is not "1"
      }),
    user_list: yup
      .array()
      .when("assign_type", {
        is: "1", // Only validate user_list if assign_type is "0"
        then: yup.array().min(1, "At least one user must be selected").required("User list is required"),
        otherwise: yup.array(), // No validation if assign_type is not "0"
      }),
  }),
];

export const TaskMgntFormConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "name",
        label: "taskmgmntformlabels.name",
        type: "input",
        mandatory: "true",
      },

      {
        name: "task_type",
        label: "taskmgmntformlabels.tasktype",
        type: "select",
        options: [
          { value: "0", label: "Activity" },
          { value: "1", label: "Notification" },
        ],
      },
      {
        name: "assign_type",
        label: "taskmgmntformlabels.assigntype",
        type: "select",
        options: [
          { value: "0", label: "Group" },
          { value: "1", label: "Users(Individual)" },
        ],
      },
      {
        name: "assign_task",
        label: "taskmgmntformlabels.assigntask",
        type: "select",
        mandatory: "true",
        options: [
          { value: "", label: "Select Option" },
          { value: "0", label: "Anyone" },
          { value: "1", label: "Everyone" },
        ],
      },
      {
        name: "role_list",
        label: "taskmgmntformlabels.rolelist",
        type: "multi_select",
        mandatory: "true",
      },
      {
        name: "user_list",
        label: "taskmgmntformlabels.userlist",
        type: "multi_select",
        mandatory: "true"
      },

      {
        name: "description",
        label: "taskmgmntformlabels.summary",
        type: "text_area",
      },
     
    ],
  },
];
