import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import XCircleblack from "../../IconComponents/XCircleblack";
import { postDataWithBody, postDataWithBodyFrGIS } from "../../../Services/Services";
import { completeIndiTask, completeIndivTask } from "../../../Services/EndPoints";
import { toast, ToastContainer } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";

const TaskModalWithComment = (props) => {
  const { title, titleValue, show, handleToggle, size, popupLabel, buttonLabel, data } = props;
  const [comment, setComment] = useState(data || ""); // Track textarea value
  const [error, setError] = useState(""); // To store validation error message

  const { keycloak } = useKeycloak();

  // Function to validate the comment length
  const validateCommentLength = () => {
    if (comment.length > 200) {
      setError("Maximum 200 characters are allowed.");
      return false;
    }
    setError(""); // Clear error message when character count is valid
    return true;
  };

  // Handle comment change and validation
  const handleCommentChange = (e) => {
    setComment(e.target.value); // Update comment state
    validateCommentLength(); // Validate character length on every change
  };

  async function saveConfigData() {
    if (!validateCommentLength()) {
      return; // Prevent submission if validation fails
    }

    let input = { "id": props.taskData.id,"task_id": props.taskData.task_id, "description": comment, "email": keycloak.idTokenParsed.email };
    var res = await postDataWithBody(completeIndivTask, input, {});
    if (res.status === "success") {
      props.showSuccessMsg();
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    } else {
      toast.error(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleToggle}
        size={size}
        className="table__modal__popup"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {title}
            {titleValue}
          </Modal.Title>
          <XCircleblack
            className="cursor-pointer"
            onClick={handleToggle}
            width="1.5em"
            height="1.5em"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="create__resource--popup">
            <div className="form-group">
              <label className="create__resource--label" htmlFor="tfld_config_value">
                {popupLabel}
              </label>
              <div className="mb-2">
                <label className="label_txt mb-2">Description</label>
                <div className="mb-2">{props.taskData.description}</div>
              </div>

              <textarea
                type="text"
                placeholder="Write your action here"
                className="form-control"
                id="modal_comment_txt_area"
                value={comment}
                onChange={handleCommentChange} // Update comment state and validate length on change
              />

              {error && <div className="text-danger mt-2">{error}</div>} {/* Show error message if validation fails */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={saveConfigData}
            type="button"
            className="btn btn-success"
            style={{ backgroundColor: "#40B2B7" }}
            disabled={error !== "" || comment.length === 0} // Disable button if there's an error or comment is empty
          >
            {buttonLabel}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskModalWithComment;
