import React, { useEffect, useState } from "react";
import "./ProfileImageComponent.css";
import { Buffer } from "buffer";
import { useDispatch } from "react-redux";
import { fetchProfileData } from "../../../CentralStore/ExportLMSTable/dataSlice";
import {
  getUserImage,
  postImage,
  userMgmtService,
  getUserProfileImage,
} from "../../../Services/EndPoints";
import { putDataFormDataWithNoidPf } from "../../../Services/ProfileServices";
import { getData } from "../../../Services/Services";
import lmsToast from "../../DMS/Common/CustomToastifier/CustomToastifier";
import { useTranslation } from "react-i18next";
import DmsButton from "../../DMS/Common/DmsButton/DmsButton";
import avatar from "../../../Assets/Images/avatar.png";
export default function ProfileImageComponent({ profileData }) {
  const dispatch = useDispatch();
  const [fileObj, setFileObj] = useState(null);
  const [imgSrc, setImgSrc] = useState(avatar); // Set default avatar initially
  const [errorMsg, setErrorMsg] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (profileData?.photo) {
      setImgSrc(
        `${process.env.REACT_APP_CORE_CONTEXT_PATH_NAME}${getUserProfileImage}/${profileData.id}`
      );
    } else {
      setImgSrc(avatar); // Fallback to the default avatar if no user image
    }
  }, [profileData]);

  const validFileTypes = ["image/png", "image/jpg", "image/jpeg"];
  const handleChange = (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    console.log("file===", file);
    if (file && validFileTypes.includes(file.type)) {
      if (file.size < 15000000) {
        setFileObj(file);
        formData.append("photo", file);
        console.log("file===", file);

        putDataFormDataWithNoidPf(postImage, formData)
          .then((res) => {
            setTimeout(() => {
              lmsToast({
                toasterType: "success",
                msg: "Successfully Profile Picture Changed",
              });
            }, 200);
            dispatch(fetchProfileData());
          })
          .catch((err) => {
            console.log(err);
          });

        setErrorMsg("");
      }
    } else {
      setErrorMsg("Invalid file type. Please select an image.");
    }
  };

  const handleClick = () => {
    const fileInputTriger = document.getElementById("file_upload");
    if (fileInputTriger) {
      fileInputTriger.click();
    }
  };

  return (
    <div className="image_container--maindiv">
      <div className="profile_image">
        <img
          src={imgSrc} 
          className="profile_image--image"
          width="100%"
          height="100%"
          onError={(e) => {
            e.target.src = avatar; // Fallback to default avatar on error
          }}
        />
        {errorMsg && <div className="error__message w-100">{errorMsg}</div>}
        <div className="edit-profile_icon">
          <DmsButton label={t("editphoto")} onClick={handleClick} disabled={true}/>
          <input
            id="file_upload"
            type="file"
            accept="image/*"
            name="file_upload"
            style={{ display: "none" }}
            onChange={(event) => {
              handleChange(event);
            }}
          />
        </div>
        <div className="user-profile_detail">
          <div>
            {`${profileData?.first_name} ${
              profileData?.middle_name ? profileData?.middle_name : ""
            } ${profileData?.last_name}`}
          </div>
          <div>{profileData?.user_name}</div>
          <div>{profileData?.phone_number}</div>
        </div>
      </div>
    </div>
  );
}
