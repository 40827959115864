import React, { useState, useEffect, useRef, useContext } from "react";
import "../DailyFloodReportComp/Hindi_Font.js";
import "../DailyFloodReportComp/DailyFloodReportCompTable.css";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import govt_logo from "../../../../Assets/Images/bihar_logo.png";
import Loader from "react-spinner-loader";
import "./DailyColdWaveRegForm.css"
//import html2pdf from 'html2pdf.js';
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent.js";
import { postDataWithBody, getData, deleteDataById } from "../../../../Services/Services.js";
import {
  getChildRegionsById,
  findRegionsByUser,
  getDailyReportByDistrict,
  getDailyFloodReportListURL,
  cleardailyReportData,
  getDailyAffectedReportByDistrict,
  getDailyColdWaveReport,
  deleteColdWaveDetail,
} from "../../../../Services/EndPoints.js";
import { useTranslation } from "react-i18next";
import DatePicker from "../../Common/Form/DatePicker/DatePicker.js";
import { RoleContext } from "../../../../index.js";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup.js";
import { Deleteicon, Editicon } from "../../../../Components/IconComponents/index.js";

const DailyColdWaveReportForm = () => {
  const roleName = useContext(RoleContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dailyReport, setDailyReport] = useState(null);
  const [popUp, setPopUp] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [clearId, setClearId] = useState(null);
  const [blockData, setBlockData] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const pdfRef = useRef(null);
  const [isdelete, setIsdelete] = useState(false);
          const [selectedId, setSelectedId] = useState(null);
  // const [districtName, setDistrictName] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // State to store selected date;

  const getBlockIdListForReport = () => {
    if (slctdBlkId == 0) {
      let blockIdList = [];
      blockData.forEach((v) => blockIdList.push(v.id));
      return blockIdList;
    } else {
      return [slctdBlkId];
    }
  };

  //   const editFloodData = async () => {
  //     if (slctdBlkId == 0) {
  //       toast.error("Please select a block to edit data.", {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //     } else {
  //       let slctdDate = document.getElementById("flood_date_picker").value;
  //       const data = await getData(
  //         getDailyFloodReportListURL
  //           .replace("ID", slctdBlkId)
  //           .replace("DATE", slctdDate)
  //       );
  //       if (data.length == 0) {
  //         toast.error("No data exists for the selected date and block", {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 2000, // Close after 2 seconds
  //         });
  //       } else {
  //         navigate("/dmsdashboard/editdfr", {
  //           state: {
  //             blockId: slctdBlkId,
  //             slctd_dt: document.getElementById("flood_date_picker").value,
  //           },
  //         });
  //       }
  //     }
  //   };
  //   const clearFloodData = async () => {
  //     if (slctdBlkId == 0) {
  //       toast.error("Please select a block to edit data.", {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //     } else {
  //       let slctdDate = document.getElementById("flood_date_picker").value;
  //       const data = await getData(
  //         getDailyFloodReportListURL
  //           .replace("ID", slctdBlkId)
  //           .replace("DATE", slctdDate)
  //       );
  //       if (data.length == 0) {
  //         toast.error("No data exists for the selected date and block", {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 2000, // Close after 2 seconds
  //         });
  //       } else {
  //         setPopUp(true);
  //         setClearId(data[0].id);
  //       }
  //     }
  //   };
  
  const getDailyReportData = async (blockListId, onInit = false) => {
    if (blockListId == null) {
      blockListId = getBlockIdListForReport();
    }
    var selectedDateStr = document.getElementById("flood_date_picker").value;
    if (selectedDateStr == "") {
      selectedDateStr = new Date().toISOString().split("T")[0];
    }
    setSelectedDate(selectedDateStr);
    setLoader(true);
  
    const index = blockListId.indexOf(0);
    if (index > -1) {
      blockListId.splice(index, 1); // Remove 0 if it exists
    }
    let inputobj = {
      region_id_list: blockListId,
      reported_on: selectedDateStr,
    };
    let dailyReportColdWaveData = await postDataWithBody(
      getDailyColdWaveReport,
      inputobj,
      {}
    );
  
    // Merge `dr_cold_wave_Cumm` data into `dr_cold_wave`
    dailyReportColdWaveData?.dr_cold_wave?.forEach((report) => {
      // Find matching cumulative data based on region_id
      const cumulativeData = dailyReportColdWaveData?.dr_cold_wave_Cumm?.find(
        (cumData) => cumData.region_id === report.region_id
      );
  
      if (cumulativeData) {
        // Add cumulative data to the report
        report.pop_affected_by_cold_wave_so_far =
          cumulativeData.pop_affected_by_cold_wave_so_far || "NA";
        report.number_of_night_shelters_so_far =
          cumulativeData.number_of_night_shelters_so_far || "NA";
        report.number_of_bonfire_places_so_far =
          cumulativeData.number_of_bonfire_places_so_far || "NA";
      }
  
      if (onInit) {
        // Fill in empty fields with a blank space
        Object.keys(report).forEach((key) => {
          report[key] = report[key] ? report[key] : " ";
        });
      }
    });
  
    setDailyReport(dailyReportColdWaveData);
    setLoader(false);
    var skillsSelect = document.getElementById("district_drop");
    var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text;
    document.getElementById("selected_dist_name").innerHTML = selectedText;
  };
  

  async function addImageProcess() {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = govt_logo;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }
  const downloadPDF = async () => {
     // Get the table element
     const table = document.getElementById("report_table");
 // Convert the table data into a format suitable for autoTable
 const tableData = [];
 const tableHeader = [];
 const rows = table.rows;
 const numCols = rows[0].cells.length;

 // Get table header (excluding the Action column)
 for (let i = 0; i < numCols - 1; i++) {  // Skip the last column (Action column)
   tableHeader.push(rows[0].cells[i].textContent.trim());
 }

 // Get table data rows (excluding the Action column)
 for (let i = 1; i < rows.length; i++) {
   const rowData = [];
   for (let j = 0; j < numCols - 1; j++) {  // Skip the last column (Action column)
     rowData.push(rows[i].cells[j].textContent.trim());
   }
   tableData.push(rowData);
 }

    // Now generate the PDF without the "Actions" column
    const content = pdfRef.current;
    const doc = new jsPDF("l", "pt", "a4");  // Set the orientation to landscape ("l")
    const image = await addImageProcess();

    let fontName = "Helvetica";
    if (i18n.language == "hi") {
        fontName = "Akshar Unicode";
    }
    doc.setFont(fontName);
    doc.setFontSize(13);
    doc.addImage(image, "png", 380, 28, 0, 0);
    var xOffset = doc.internal.pageSize.width / 2;
    const selectedDate = new Date(document.getElementById("flood_date_picker").value).toLocaleDateString("en-GB");

    doc.text(t("floodreport.govt_name"), xOffset, 120, { align: "center" });
    doc.text(t("floodreport.department_name"), xOffset, 140, { align: "center" });
    doc.text(
        t("Cold Wave Report") +
        " (" +
        t("floodreport.dated_on_lbl") +
        " " +
        selectedDate +
        ")",
        xOffset,
        160,
        { align: "center" }
    );
    doc.autoTable({
      head: [tableHeader],
      body: tableData,
        startX: 0,
        startY: 190,
        theme: "grid",
        rowPageBreak: "avoid",
        bodyStyles: { lineColor: [0, 0, 0] },
        styles: {
            font: fontName,
            fontSize: 2,
            cellPadding: 3,
            lineColor: [0, 0, 0], // Black lines
            lineWidth: 0.1, // Adjust thickness of lines
        },
        headStyles: {
            fillColor: [255, 255, 255], // White background for header
            textColor: [0, 0, 0],       // Black text for header
            fontStyle: "bold",          // Bold header text
            lineColor: [0, 0, 0],       // Ensure grid lines stay black for the header
            lineWidth: 0.1,             // Ensure lines are drawn in header
        },
        columnStyles: {
            0: { cellWidth: 29 }, // S.No
            1: { cellWidth: 70 }, // District Name
            2: { cellWidth: 42 }, // Number of places...
            3: { cellWidth: 42 },
            4: { cellWidth: 42 },
            5: { cellWidth: 42 },
            6: { cellWidth: 46 },
            7: { cellWidth: 42 },
            8: { cellWidth: 42 },
            9: { cellWidth: 42 },
            10: { cellWidth: 42 },
            11: { cellWidth: 42 },
            12: { cellWidth: 42 },
            13: { cellWidth: 42 },
            14: { cellWidth: 42 },
            15: { cellWidth: 42 },
            16: { cellWidth: 42 },
            // Do not include the 17th column (Actions)
        },
        rowPageBreak: "auto",
        pageBreak: "auto",
        didParseCell: function (data) {
          if (data.cell.raw && data.cell.raw.nodeType === 1) {
            const cellElement = data.cell.raw;
                if (data.cell.raw.querySelector("b")) {
                    data.cell.styles.textColor = "red";
                }
                if (data.cell.raw.querySelector("span")) {
                    data.cell.styles.halign = "center";
                }
            } else {
                console.warn("data.cell.raw is undefined", data);
            }
        },
        styles: {
            font: fontName,
            fontSize: 9,
            cellWidth: "auto",
            halign: "left",
            cellPadding: 3,
        },
    });
    const distName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';

    let finalY = doc.lastAutoTable.finalY; // The y position on the page
    doc.setFontSize(10);
    doc.text(550, finalY + 100, "Signature of District Officers / Additional District Magistrate", { maxWidth: 300 });
    doc.text(550, finalY + 120, "(ADM) (Disaster Management)/ Officer in Charge", { maxWidth: 300 });
    doc.text(550, finalY + 140, `(District Disaster Management)   [${distName}]`, { maxWidth: 300 });

    doc.text(50, finalY + 100, "Letter No.: ", { maxWidth: 300 });
    doc.text(50, finalY + 120, "Date: ", { maxWidth: 300 });
    doc.text(50, finalY + 140, "Copy: ", { maxWidth: 300 });

    doc.save("daily_cold_wave_report.pdf");
};



  // const downloadExcel = async (tableData, tableHeaders, fileName = "ColdWaveReport.xlsx") => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Cold Wave Report");
  
  //   // Define columns based on tableHeaders
  //   worksheet.columns = tableHeaders.map((header) => ({
  //     header: header.label,
  //     key: header.key,
  //     width: 25, // Adjust as needed
  //   }));
  
  //   // Process data and replace null or undefined with "NA"
  //   const processedData = tableData.map((row, index) => {
  //     const processedRow = { slno: index + 1 }; // Add Sl. No dynamically
  
  //     // Replace null or undefined with "NA" for all other keys
  //     tableHeaders.forEach((header) => {
  //       if (header.key !== "slno") { // Skip slno column
  //         processedRow[header.key] = row[header.key] != null ? row[header.key] : "NA";
  //       }
  //     });
  //     return processedRow;
  //   });
  
  //   // Add rows to the worksheet
  //   processedData.forEach((row) => worksheet.addRow(row));
  
  //   // Style header row
  //   worksheet.getRow(1).eachCell((cell) => {
  //     cell.font = { bold: true };
  //     cell.alignment = { horizontal: "center", vertical: "middle" };
  //   });
  
  //   // Generate and save the Excel file
  //   try {
  //     const buffer = await workbook.xlsx.writeBuffer();
  //     const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = fileName;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Failed to export Excel file:", error);
  //   }
  // };
  
  const deletePopUp = (id) => {
    setSelectedId(id);
    setIsdelete(!isdelete);
  };

  const deleteTableDataById = async () => {
    try {
      const response = await deleteDataById(deleteColdWaveDetail, selectedId);
      if (response?.status === "success") {
        await getDailyReportData()
        toast.success(
          response.msg,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          }
        ); 
      } else {
        toast.error(
          response.msg || t("delete_failure_message"),
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          }
        );
      }
    } 
    // catch (error) {
    //   toast.error(t("delete_error_message"), {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 2000,
    //   });
    //   console.error("Delete error:", error);
    // } 
    finally {
      setIsdelete(false);
      setSelectedId(null);
    }
  };

  
  
  const getDistrictData = async () => {
    try {
      const distList = await getData(findRegionsByUser);

      if (Array.isArray(distList) && distList.length > 0) {
        let blockdata = await getBlockListByDistId(distList[0].id);
        setSlctdBlkId(0);
        let blockIdList = [];
        blockdata.forEach((v) => blockIdList.push(v.id));
        setDistrictData(distList);
        if(sessionStorage.getItem("date") && sessionStorage.getItem("districtNameValue") ) {
          getDailyReportData(blockIdList,true);
          sessionStorage.removeItem("date");
          sessionStorage.removeItem("districtNameValue");
        }
        setSelectedDistrictId(distList[0].id);
      } else {
        console.error("Error: District data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  useEffect(() => {
    document.getElementById("flood_date_picker").valueAsDate = new Date();
    getDistrictData();
  }, []);

  const getFormattedDate = (dateStr) => {
    return <Moment format="DD/MM/YYYY">{dateStr}</Moment>;
  };

  const getBlockListByDistId = async (distId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [distId],
        {}
      );
      blockList.forEach(function (elem, index) {
        elem["value"] = elem["name"];
      });
      blockList.unshift({ id: 0, value: "All" });
      setBlockData(null);
      setTimeout(function () {
        setBlockData(blockList);
      }, 100);
      return blockList;
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };
  async function getDistrictDataByCode(event) {
    setSelectedDistrictName(event.target.value);
    setSelectedDistrictId(event.target.value);
    getBlockListByDistId(event.target.value);
  }
  function setSelectedBlockIdVal(event) {
    setSlctdBlkId(event.target.value);
  }
  const changeSelectedate = () => {
    /*if (selectedDistrictId && selectedDate) {
      getDailyReportData(selectedDistrictId, selectedDate);
    } else {
      console.error("Please select both district and date.");
    }*/
  };

  const handleTogglePopup = () => {
    setPopUp(!popUp);
  };

  const onClickSucesshandler = async () => {
    await postDataWithBody(cleardailyReportData + clearId, {}, {});
    setPopUp(false);
  };
  console.log("DISTRICT:",districtData);

  // const tableHeaders = [
  //   { label: "Sl. No", key: "slno" },
  //   { label: "District Name", key: "district" },
  //   { label: "Number of Bonfire Places (Today)", key: "number_of_bonfire_places_today" },
  //   { label: "Number of Bonfire Places (Total)", key: "number_of_bonfire_places" },
  //   { label: "Quantity of Wood Burnt (Today)", key: "quantity_of_wood_burnt_today" },
  //   { label: "Quantity of Wood Burnt (Total)", key: "quantity_of_wood_burnt" },
  //   { label: "Population Affected by Cold Wave (Today)", key: "pop_affected_by_cold_wave_today" },
  //   { label: "Population Affected by Cold Wave (Total)", key: "pop_affected_by_cold_wave" },
  //   { label: "Number of Deaths (Today)", key: "number_of_deaths_today" },
  //   { label: "Number of Deaths (Total)", key: "number_of_deaths" },
  //   { label: "Amount Allocated by Department", key: "amount_allocated_by_department" },
  //   { label: "Updated Amount Spent", key: "updated_amount_spent" },
  //   { label: "Number of Night Shelters (Today)", key: "number_of_night_shelters_today" },
  //   { label: "Number of Night Shelters (Total)", key: "number_of_night_shelters" },
  //   { label: "Number of People Taking Shelter in Night Shelters (Today)", key: "number_of_ppl_taking_shelter_in_night_shelters_today" },
  //   { label: "Number of People Taking Shelter in Night Shelters (Total)", key: "number_of_ppl_taking_shelter_in_night_shelters" },
  //   { label: "Number of Blankets Distributed (Today)", key: "number_of_blankets_distributed_today" },
  //   { label: "Number of Blankets Distributed (Total)", key: "number_of_blankets_distributed" },
  // ];
  
  console.log("SELECTEDDISCTRICT",selectedDistrictName);

  // const districtName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';

  // console.log("DISTRICTNAME",districtName);

  // Calculate if the report is older than 3 days
const isOlderThanThreeDays = (reportDate) => {
  const currentDate = new Date();
  const reportDateObj = new Date(reportDate);
  const diffInTime = currentDate.getTime() - reportDateObj.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24); // Convert time difference to days
  return diffInDays > 3;
};

  return (
    <div class="flood__report--container">
      {isdelete && (
                <Confirmpopup
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={() => deletePopUp(null)}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
      {popUp && (
        <Confirmpopup
          show={popUp}
          handleToggle={handleTogglePopup}
          title="Confirmation"
          message="Are You sure to clear"
          cancel="No"
          success="Yes"
          onClickSucesshandler={onClickSucesshandler}
        />
      )}
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="ps-2 d-flex">
        <div className="me-2 region_type_container">
          <label className="mb-2">{t("floodreport.district")}</label>
          <div>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="All District"
              options={districtData}
              onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        </div>

        {/* <div className="me-2 region_type_container">
          <label className="mb-2">Block</label>
          <SelectComponent
            id="block_drop"
            value={selectedBlockId}
            onChange={setSelectedBlockIdVal}
            placeholder="All Blocks"
            options={blockData}
          />
        </div> */}

        <div>
          <label className="mb-2">{t("floodreport.reported_on")}</label>
          <div className="date_picker_flood_container me-1">
            <DatePicker
              selected={selectedDate}
              maxDate={new Date().toISOString().split("T")[0]}
              name="flood_date_picker"
              onChange={(date) => {
                console.log("Selected date:", date);
                setSelectedDate(date);
              }}
              className="form-control date-picker date_picker_flood"
            />
          </div>
        </div>
        <button
          className="btn btn-success ms-2 btn-sm flood__report--button"
          onClick={() => getDailyReportData(null)}>
          {t("floodreport.buttonapply")}
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={downloadPDF}>
          {t("floodreport.download")}
        </button>
        {/* <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => downloadExcel(dailyReport?.dr_cold_wave, tableHeaders)}>
          {"Export"}
        </button> */}
      </div>
      <div className="pe-3 overflow-scroll" ref={pdfRef}>
        <div className="text-center fw-bold d-none">
          Daily Flood Report Dated on ({getFormattedDate(selectedDate)})
        </div>
        <table id="report_table" className="flood__report--table" border="2" style={{width: "200%"}} >
  <thead>
    <tr>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "1%" }}>{t("coldwavereport.slno")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.block_name")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.number_of_bonfire_places_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.number_of_bonfire_places_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.quantity_wood_burnt_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.quantity_wood_burnt_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.population_affected_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.population_affected_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.deaths_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.deaths_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.amount_allocated")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.amount_spent_updated")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.night_shelters_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.night_shelters_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.people_in_shelter_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.people_in_shelter_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.blankets_distributed_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.blankets_distributed_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>Actions</td>
    </tr>
  </thead>
  <tbody>
  {dailyReport?.dr_cold_wave?.map((report, index) => {
    console.log("Report Data:", report); // Log the report object
    const isSuperAdmin = roleName.roleName === process.env.REACT_APP_SUPER_ADMIN;
      const isDisabled = !isSuperAdmin && isOlderThanThreeDays(selectedDate); 

    return (
      <tr style={{ height: "30pt" }} key={index}>
        <td>{index + 1}</td> {/* Sl. No */}
        <td>{report.block_name || "NA"}</td>
        <td>{report.number_of_bonfire_places || "NA"}</td>
        <td>{report.number_of_bonfire_places_so_far || "NA"}</td>
        <td>{report.quantity_of_wood_burnt || "NA"}</td>
        <td>{report.quantity_of_wood_burnt_so_far || "NA"}</td>
        <td>{report.pop_affected_by_cold_wave || "NA"}</td>
        <td>{report.pop_affected_by_cold_wave_so_far || "NA"}</td>
        <td>{report.number_of_deaths || "NA"}</td>
        <td>{report.number_of_deaths_so_far || "NA"}</td>
        <td>{report.amount_allocated_by_department ? report.amount_allocated_by_department : ""}</td>
        <td>{report.updated_amount_spent || "NA"}</td>
        <td>{report.number_of_night_shelters || "NA"}</td>
        <td>{report.number_of_night_shelters_so_far || "NA"}</td>
        <td>{report.number_of_ppl_taking_shelter_in_night_shelters || "NA"}</td>
        <td>{report.number_of_ppl_taking_shelter_in_night_shelters_so_far || "NA"}</td>
        <td>{report.number_of_blankets_distributed || "NA"}</td>
        <td>{report.number_of_blankets_distributed_so_far || "NA"}</td>
        <td style={{ textAlign: "center", width: "5%" }}><span
    className={`icon__position ${isDisabled ? 'disabled' : ''}`} // Add class for disabled icon
    onClick={() => {
      if (!isDisabled) { // Allow click only if not disabled
      const distName = districtData?.find(x => x.id == selectedDistrictId)?.value || '';
      sessionStorage.setItem("date",JSON.stringify(selectedDate));
      sessionStorage.setItem("districtNameValue",JSON.stringify(distName));
      // console.log("distid","distName",selectedDistrictId,districtName);
      navigate("/daily-cold-wave/registration-form", {
        state: { 
          data: report, // Pass data to the next page
          date: selectedDate,
          districtNameValue: distName
        },
      });
    }
    //   console.log("Human Loss Row Data:", props.row.original); // Log data after navigate
    }}
  >
    <Editicon  className={`cursor-pointer me-2 ${isDisabled ? 'disabled_icon' : ''}`} />
  </span>
  <span className={`icon__position ${isDisabled ? 'disabled' : ''}`} // Add class for disabled icon
  onClick={() => {
    if (!isDisabled) { // Allow click only if not disabled
      deletePopUp(report.id); // Trigger delete action
    }
            }
                
                } style={{ cursor: isDisabled ? "not-allowed" : "pointer", marginRight: "10px" }}>
                <Deleteicon className={`cursor-pointer ${isDisabled ? 'disabled_icon' : ''}`} />
              </span></td>
      </tr>
    );
  })}
</tbody>

</table>


      </div>
    </div>
  );
};

export default DailyColdWaveReportForm;
