import React, { useState, useEffect } from "react";
import Table from "../../../../Components/UiComponents/Table/Table";
import { useNavigate } from "react-router-dom";
import {
  Editicon,
  Deleteicon,
  PaperClipIcon,
} from "../../../../Components/IconComponents";
import { useDispatch } from "react-redux";
import { viewtaskcolumnsForExportView } from "../../../../JsonData/CreateTaskConfig";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import {
  downloadFile,
  getExportedColumnsData,
} from "../../../../Components/UiComponents/utils";
import { getTaskListURL, deleteTaskURL } from "../../../../Services/EndPoints";
import { getData, putDataWithBody } from "../../../../Services/Services";
import Moment from "react-moment";
import { toast } from "react-toastify";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import Loader from "react-spinner-loader";
import { useTranslation } from "react-i18next";

const ViewTask = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [viewTypeData, setViewTypeData] = useState([]);
  const [incidentTypeData, setIncidentTypeData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    getTaskLists();
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const getShortenedText = (strLength, fulltext) => {
    let abbreviatedText = "";
    if (fulltext.length > strLength) {
      abbreviatedText = fulltext.substring(0, 25) + "...";
    } else {
      abbreviatedText = fulltext;
    }
    return <span title={fulltext}>{abbreviatedText}</span>;
  };
  async function getTaskLists() {
    setLoader(true);
    var taskList = await getData(getTaskListURL);
    let sortedList = taskList.sort((a, b) => {
      return new Date(b.created_on) - new Date(a.created_on);
    });
    setLoader(false);
    setData(sortedList);
    setRowCount(sortedList.length);
  }
  function addNewHandler() {
    navigate("/planning/create-task");
  }

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = viewtaskcolumnsForExportView;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("taskmanagementtableheaders.sopname"),
        accessor: "task_name",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return getShortenedText(15, props.value);
          } else {
            return "NA";
          }
        },
      },
      {
        Header: t("taskmanagementtableheaders.incidenttype"),
        accessor: "incident_type",

        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return getShortenedText(15, props.value);
          } else {
            return "NA";
          }
        },
      },
      {
        Header: t("taskmanagementtableheaders.tasktype"),
        accessor: "task_type",
        disableSortBy: true,
      },
      {
        Header: t("taskmanagementtableheaders.assignedto"),
        accessor: "role_name",

        Cell: (props) => {
          if (props.value) {
            return getShortenedText(25, props.value);
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("taskmanagementtableheaders.Createdon"),
        accessor: "created_on",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: false,
      },
      {
        Header: "Attachment",
        accessor: "media_file_path",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value && props.value != null) {
            let downloadAttachmentURL =
              process.env.REACT_APP_APP_SERVER_ADDRESS +
              "/" +
              process.env.REACT_APP_CORE_CONTEXT_PATH_NAME +
              "/download/download_attachments/?directoryPath=" +
              props.value +
              "&fileName=Tasks.zip";
            return (
              <div className="text-center">
                <span className="icon__position">
                  <div
                    onClick={() =>
                      downloadFile(
                        downloadAttachmentURL,
                        "Tasks" + new Date().getTime() + ".zip"
                      )
                    }
                  >
                    <PaperClipIcon className="cursor-pointer" />
                  </div>
                </span>
              </div>
            );
          } else {
            return "";
          }
        },
      },
      {
        Header: t("taskmanagementtableheaders.action"),
        disableSortBy: true,

        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteTaskURL, data, {});
            if (res.status == "success") {
              getTaskLists();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };

          return (
            <div className="usertable__actionsicon">
              <span
                onClick={() => {
                  navigate("/planning/create-task", {
                    state: {
                      data: props.row.original,
                    },
                  });
                }}
              >
                <Editicon value={props.value} className="cursor-pointer me-2" />
              </span>

              <span onClick={deletePopUp}>
                <Deleteicon
                  value={props.value}
                  className="cursor-pointer mr-1"
                />
              </span>
              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.title}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t]
  );

  return (
    <div>
      <div className="create-user">
        <Loader
          show={loader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
        <div className="datatable__container">
          <Table
            data={data}
            title=""
            addButtonLabel={t("taskaddButtonLabel")}
            addNewHandler={addNewHandler}
            showFilter={false}
            setSearchData={setSearchData}
            columns={columns}
            numberOfRows={10}
            showExport={false}
            showReset={false}
            onExpand={false}
            showSearch={true}
            isHeaderFixed={true}
            rowCount={rowCount} // Pass rowCount to the Table component
            // addButtonLabel="Add New"
            // addNewHandler={addNewHandler}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTask;
