import React, { useEffect, useState } from "react";
import DMSHeader from "../../DMSHeader/DMSHeader";
//import DailyFloodReportCompTable from "./DailyFloodReportCompTable";
import { useTranslation } from "react-i18next";
import HumanLossReportForm from "./HumanLossReportForm";

const HumanLossReport = () => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("Human Loss Report");

  // Function to update title...
  const updateTitle = (view) => {
    if (view === "Primary") {
      setTitle("Human Loss Report (Primary)");
    } else if (view === "Detailed") {
      setTitle("Human Loss Report (Detailed)");
    }
  };

  return (
    <div>
      <div className="widget-container">
        <DMSHeader title={title} />
      </div>
      <div className="widget-container p-1">
        <HumanLossReportForm onViewChange={updateTitle}/>
      </div>
    </div>
  );
};

export default HumanLossReport;

