import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Modal from "react-bootstrap/Modal";
import XCircleblack from "../../../../../src/Components/IconComponents/XCircleblack";
import {
  Editicon,
  Deleteicon,
  Viewicon,
} from "../../../../Components/IconComponents";
import { toast, ToastContainer } from "react-toastify";
import Table from "../../../../Components/UiComponents/Table/Table";
import Moment from "react-moment";
import Loader from "react-spinner-loader";
import {
  SOPTableData,
  sopcolumnsForExport,
} from "../../../../JsonData/SOPConfig";
import { getData, putDataWithBody } from "../../../../Services/Services";
import {
  getMasterSOPListURL,
  getActiveMasterSOPList,
  deleteSopById,
} from "../../../../Services/EndPoints";
import { useDispatch } from "react-redux";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import { useTranslation } from "react-i18next";
import { RoleContext } from "../../../..";
import { Button } from "react-bootstrap";

const SOPTable = forwardRef((props, ref) => {
  const { roleName } = useContext(RoleContext);
  const dispatch = useDispatch();
  const qs = require("qs");
  const [SOPData, setSOPData] = useState([]);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [rowCount, setRowCount] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (props.locationInstance && props.locationInstance.msg != "") {
      toast.success("Agency has been created successfully.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds...
      });
    }
    getSOPMasterList();
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const addNewHandler = () => {
    navigate("/planning/create-sop");
  };
  const openSOPDataTable = async () => {
    setShow(true);
  };
  const getShortenedText = (strLength, fulltext) => {
    let abbreviatedText = "";
    if (fulltext.length > strLength) {
      abbreviatedText = fulltext.substring(0, 15) + "...";
    } else {
      abbreviatedText = fulltext;
    }
    return <span title={fulltext}>{abbreviatedText}</span>;
  };
  const hideModal = () => {
    setShow(false);
  };
  const getSOPMasterList = async () => {
    setLoader(true);
    var sopMasterList = await getData(getActiveMasterSOPList);
    let sortedList = sopMasterList.sort((a, b) => {
      return new Date(b.created_on) - new Date(a.created_on);
    });
    setLoader(false);
    setSOPData(sortedList);
    setRowCount(sortedList.length);
  };

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = sopcolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("sopmanagementtableheaders.name"),
        accessor: "name",
        disableSortBy: true,

        Cell: (props) => {
          return (
            <span /*onClick={() => openSOPDataTable(props.value)}*/>
              {props.value}
            </span>
          );
        },
      },

      {
        Header: t("sopmanagementtableheaders.description"),
        accessor: "description",
      
        Cell: (props) => {
          const [showModal, setShowModal] = useState(false);
          const [fullDescription, setFullDescription] = useState("");
          const [highlightedDescription, setHighlightedDescription] = useState(""); // New state for highlighted description
      
          const handleShowModal = (description) => {
            setFullDescription(description);
            setHighlightedDescription(description); // Set the description being viewed to highlight
            setShowModal(true);
          };
         
          const handleCloseModal = () => {
            setShowModal(false);
            setHighlightedDescription(""); // Reset the highlighted description when the modal is closed
          };
      
          const descriptionText = props.value || "NA";
          const truncatedDescription = getShortenedText(15, descriptionText); // Reuse your helper function to shorten text
      
          // Apply background color if the description is currently being viewed in the modal
          const isHighlighted = descriptionText === highlightedDescription;
          if (showModal) {
            setTimeout(() => {
              let el = document.querySelector('.modal-content');
              el.style.cssText = 'background-color: #1c2038 !important; border-radius: 20px !important;';
            }, 0);
          }
      
          return (
            <span>
              <span
                title={descriptionText} // Tooltip with full description on hover
                style={{
                  cursor: 'pointer',  // Added pointer cursor to indicate clickability
                  backgroundColor: isHighlighted ? '#18A3A0' : 'transparent', // Highlight background when viewed
                  padding: '2px 5px', // Add padding to give a little space around the text
                  borderRadius: '5px', // Rounded corners for the highlighted background
                }}
                onClick={() => handleShowModal(descriptionText)} // Open the modal with full description
              >
                {truncatedDescription}
              </span>
      
              {/* Modal to display the full description */}
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                dialogClassName="custom-modal"
                centered
                style={{
                  borderRadius: '20px',  // Smooth rounded edges
                  border: 'none',        // Remove border to avoid white edges
                  top: '20%',  
                }}
              >
                <Modal.Header
                  style={{
                    backgroundColor: '#1c2038', // Dark blue background for header
                    color: 'white',              // White text in the header
                    borderBottom: 'none',       // Remove bottom border to prevent white edges
                  }}
                >
                  <Modal.Title style={{ color: 'white' }}>Full Description</Modal.Title>
                  <XCircleblack
                    onClick={handleCloseModal}
                    width="1.5em"
                    height="1.5em"
                    className="cursor-pointer"
                  />
                </Modal.Header>
                <Modal.Body
                  style={{
                    backgroundColor: '#252b48', // Blue background for body
                    color: 'white',              // White text in the body
                    border: 'none',              // Remove border to prevent white edges
                    borderRadius: '0px',        // Remove radius to make it seamless
                    padding: '20px',             // Added padding for spacing
                    lineHeight: '1.8', 
                  }}
                >
                  <p>{fullDescription}</p>
                </Modal.Body>
                <Modal.Footer
                  style={{
                    backgroundColor: '#252b48', // Darker blue footer
                    color: 'white',              // White text in footer
                    borderTop: 'none',          // Remove top border to prevent white edges
                  }}
                >
                  <Button
                    variant="secondary"
                    onClick={handleCloseModal}
                    style={{
                      backgroundColor: '#252b48', // Dark blue button
                      borderColor: '#003366',     // Dark border for button
                      borderRadius: '5px',        // Rounded button edges
                    }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </span>
          );
        },
        disableSortBy: true,
      },      
      {
        Header: t("sopmanagementtableheaders.tasklist"),
        accessor: "task_list",

        Cell: (props) => {
          if (props && props.value) {
            if (props.value.length > 0) {
              return getShortenedText(15, props.value);
            }
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("sopmanagementtableheaders.resourcelist"),
        accessor: "resource_list",
        Cell: (props) => {
          if (props && props.value) {
            if (props.value.length > 0) {
              return getShortenedText(15, props.value);
            }
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("sopmanagementtableheaders.createdon"),
        accessor: "created_on",
        disableSortBy: false, // Enable sorting
        sortType: (rowA, rowB, columnId) => {
          const dateA = new Date(rowA.values[columnId]);
          const dateB = new Date(rowB.values[columnId]);
          return dateB - dateA; // Newest first
        },
        Cell: (props) => {
          if (props && props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
      },      
      {
        Header: t("sopmanagementtableheaders.action"),
        disableSortBy: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteSopById, data, {});
            if (res.status == "success") {
              getSOPMasterList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };

          return (
            <div className="usertable__actionsicon">
              <span
                onClick={() =>
                  navigate("/planning/view-sopinfo", {
                    state: {
                      data: props.row.original,
                    },
                  })
                }>
                <Viewicon value={props.value} className="cursor-pointer me-2" />
              </span>

              <span
                onClick={() =>
                  navigate("/planning/create-sop", {
                    state: {
                      data: props.row.original,
                    },
                  })
                }>
                <Editicon value={props.value} className="cursor-pointer me-2" />
              </span>
              {roleName === process.env.REACT_APP_SUPER_ADMIN && (
                <span onClick={deletePopUp}>
                  <Deleteicon
                    value={props.value}
                    className="cursor-pointer mr-1"
                  />
                </span>
              )}
              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  // data={data}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.task}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t,roleName]
  );
  return (
    <div className="create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        {SOPTableData && (
          <Table
            data={SOPData}
            columns={columns}
            setSearchData={setSearchData}
            numberOfRows={10}
            addButtonLabel={t("sopaddButtonLabel")}
            addNewHandler={addNewHandler}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid # #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        )}
      </div>

      <Modal show={show} size="lg" className="table__modal__popup" centered>
        <Modal.Header>
          <Modal.Title>SOP Data</Modal.Title>
          <XCircleblack
            className="cursor-pointer"
            onClick={hideModal}
            width="1.5em"
            height="1.5em"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="create__resource--popup">
            <div class="form-group">
              <Table
                data={SOPData}
                columns={columns}
                numberOfRows={5}
                showExport={false}
                showFilter={false}
                showReset={false}
                onExpand={false}
                headerTableHeadStyle={{
                  background: "#18a3a0",
                  color: "#FFFFFF",
                }}
                tableStyle={{ border: "none", padding: "0px" }}
              />
            </div>
            <div class="form-group">
              <Table
                data={SOPData}
                columns={columns}
                numberOfRows={5}
                showExport={false}
                showFilter={false}
                showReset={false}
                onExpand={false}
                headerTableHeadStyle={{
                  background: "#18a3a0",
                  color: "#FFFFFF",
                }}
                tableStyle={{ border: "none", padding: "0px" }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
});

export default SOPTable;
