import React, { useState, useContext, useEffect } from "react";
import "./Signout.css";
import { Modal, Button } from "react-bootstrap";
import { Logout, XCircleblack } from "../../Components/IconComponents";
import { useKeycloak } from "@react-keycloak/web";
import { appserveraddr, createUserLog } from "../../Services/EndPoints";
import { LayoutState } from "../../Context/LayoutContext/LayoutContext";
import { useTranslation } from 'react-i18next';
import { ThemeContext } from "../..";
import { postDataWithBody } from "../../Services/Services";
const Signout = () => {
  const [isShow, invokeModal] = useState(false);
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { collapsed } = LayoutState();
  const hideModal = () => {
    invokeModal(false);
  };
  const { theme } = useContext(ThemeContext);

  const handleBeforeUnload = (event) => {
    const payload = JSON.parse(sessionStorage.getItem("inputDetails") || "{}");
    payload.activity = "Log Out";
    payload.date = new Date().toISOString().split("T")[0];
    payload.time = new Date().toLocaleTimeString("en-US", { hour12: false });

    const response = postDataWithBody(createUserLog, payload, {}).then((response) => response.json())
      .then((data) => {
        console.log("Successfully sent log out data", data);
      })
      .catch((error) => {
        console.error("Error sending log out data", error);
      });
  };
  useEffect(() => {

    // Attach the event listener for 'beforeunload' event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);

  }, []); // Empty dependency array ensures this runs only once when the component is mounted


  const signOutHandler = async () => {
    const payload = JSON.parse(sessionStorage.getItem("inputDetails"));
    payload.activity = "Log Out";
    payload.date =  new Date().toISOString().split("T")[0];
    payload.time =  new Date().toLocaleTimeString("en-US", { hour12: false });
    const response = await postDataWithBody(createUserLog, payload, {});
    localStorage.clear();
    keycloak.logout({ redirectUri: appserveraddr });
  };

  // useEffect(() => {
  //   keycloak.onTokenExpired = async () => {
  //     const payload = JSON.parse(sessionStorage.getItem("inputDetails") || "{}");
  //     payload.activity = "Log Out";
  //     payload.date =  new Date().toISOString().split("T")[0];
  //     payload.time =  new Date().toLocaleTimeString("en-US", { hour12: false });

  //     try {
  //       await postDataWithBody(createUserLog, payload, {});
  //       console.log("Session timeout activity logged");
  //     } catch (error) {
  //       console.error("Error logging session timeout activity:", error);
  //     }
  //   };
  // }, [keycloak]);

//   window.addEventListener("beforeunload", (event) => {
//     const payload = JSON.parse(sessionStorage.getItem("inputDetails") || "{}");
//     payload.activity = "Log Out";

//     // Log payload for debugging
//     console.log("Sending Payload:", payload);

//     // Prevent default behavior to give time for the fetch request
//     event.preventDefault();

//     // Add debug delay (this will only affect logging and allow time for the fetch request)
//     setTimeout(() => {
//         console.log("Debug delay complete");

//         // Make the fetch request with headers
//         fetch("http://localhost:3001/dms-service/user/createUserLog", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",  // Set the appropriate content type
//                 Authorization: 'Bearer '+sessionStorage.getItem("auth_token"),  // Include your token if needed
//             },
//             body: JSON.stringify(payload),
//         })
//         .then((response) => response.json())
//         .then((data) => {
//             console.log("Successfully sent log out data", data);
//         })
//         .catch((error) => {
//             console.error("Error sending log out data", error);
//         });
//     }, 1000); // 1-second delay for debugging

//     console.log("Debug delay initiated");
// });




  return (
    // <div className={`${collapsed ? "sb-collapsed" : "sidebar1"}`}>
    //    <div className="sidebar__content1">
    <div className={collapsed ? " sidebarCollapsed" : "sidebar1"}>
      <div className="sidebar__content1" onClick={invokeModal}>
        <span>
          <Logout width="1.1em" height="1.1em" />
          {!collapsed ? t("signout") : null}
        </span>
      </div>
      <Modal id={theme} show={isShow}>
        <Modal.Header>
          {" "}
          <XCircleblack
            className="cursor-pointer close-position"
            onClick={hideModal}
            width="1.5em"
            height="1.5em"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="exit_btns_container">
            <div className="row mt-2 mb-3">
              <div className="col-6">
                <div
                  className={`exit_btns sidebar__bottom ${collapsed && " logout"
                    }`}
                  onClick={signOutHandler}
                >
                  Logout
                </div>
              </div>
              <div className="col-6">
                This will log you out from all the applications.
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Signout;
