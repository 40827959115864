import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSearchParams } from "react-router-dom";
import "./MasterDataTable.css";
import { Editicon, Deleteicon } from "../../../../Components/IconComponents";
import { useKeycloak } from "@react-keycloak/web";
import Table from "../../../../Components/UiComponents/Table/Table";
import { Modal, Button } from "react-bootstrap";
import Moment from "react-moment";
import ResourceTypeDetails from "../../../../Components/UiComponents/ResourceTypeDetails/ResourceTypeDetails";
import ModalResourceType from "../../../../Components/UiComponents/ModalResourceType/ModalResourceType";
import { getData, putDataWithBody } from "../../../../Services/Services";
import {
  masterDataAttrTypeListURL,
  getConfigsByCategoryName,
  deleteConfig,
} from "../../../../Services/EndPoints";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent";
import Loader from "react-spinner-loader";
import { toast, ToastContainer } from "react-toastify";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import { useTranslation } from "react-i18next";
const MasterDataTable = forwardRef((props, ref) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { keycloak } = useKeycloak();
  const [userdata, setUserdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const qs = require("qs");
  const [masterTypeData, setMasterTypeData] = useState([]);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [isShow, invokeModal] = React.useState(false);
  const [showPop, setShowPop] = React.useState(false);
  const [updatePopUp, setUpdatePopUp] = React.useState(false);
  const [selectedConfigType, setSelectedConfigType] = React.useState(null);
  const [resourceCategoryData, setResourceCategoryData] = useState([]);
  const [modalData, setModalData] = useState();
  const [rowCount, setRowCount] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getMasterDataAttr();
  }, []);

  const addUpdateHandler = (props) => {
    setUpdatePopUp(!updatePopUp);
    setModalData(props);
    setShowPop(false); // Close the add new modal if open
  };

  const addNewHandler = () => {
    setShowPop(!showPop);
  };

  useImperativeHandle(ref, () => ({
    refreshConfigMasterTable(configId) {
      setSelectedConfigType(configId);
      refreshConfigMasterTable(configId);
    },
  }));

  function showSuccessMsg(configId) {
    refreshConfigMasterTable(configId);
    toast.success("Master Data type added successfully.", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Close after 2 seconds
    });
  }

  async function refreshConfigMasterTable(configId) {
    var mastrDataList = await getData(getConfigsByCategoryName + configId);
    mastrDataList.sort((a, b) => {
      return b.created_on - a.created_on;
    });
    setMasterTypeData(mastrDataList);
    setRowCount(mastrDataList.length);
  }
  async function getMasterDataAttr() {
    setLoader(true);
    var attrList = await getData(masterDataAttrTypeListURL);
    setLoader(false);
    for (let attr in attrList) {
      attrList[attr]["id"] = attrList[attr]["code"];
    }
    refreshConfigMasterTable(attrList[0].id);
    setSelectedConfigId(attrList[0].id);
    setResourceCategoryData(attrList);
  }

  async function getConfigDataByCode(event) {
    setSelectedConfigType(event.target.value);
    setSelectedConfigId(event.target.value);
    var mastrDataList = await getData(
      getConfigsByCategoryName + event.target.value
    );
    setMasterTypeData(mastrDataList);
    setRowCount(mastrDataList.length);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t("configurationmgttableheaders.value"),
        accessor: "value",
        leftAlign: true,
        disableSortBy: true,
      },
      {
        Header: t("configurationmgttableheaders.createdby"),
        accessor: "email",
        leftAlign: true,
        disableSortBy: true,
        Cell: (props) => {
          if (props.value && props.value != null) {
            let name = props.value;
            if (props.value.length > 15) {
              name = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{name}</span>;
          } else {
            return "SYSTEM";
          }
        },
      },
      {
        Header: t("configurationmgttableheaders.createdon"),
        accessor: "created_on",
        disableSortBy: true,
        Cell: (props) => {
          return <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>;
        },
      },
      {
        Header: t("configurationmgttableheaders.updatedby"),
        accessor: "updated_by_email",
        leftAlign: true,
        disableSortBy: true,
        Cell: (props) => {
          if (props.value && props.value != null) {
            let name = props.value;
            if (props.value.length > 15) {
              name = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{name}</span>;
          } else {
            return "NA";
          }
        },
      },
      {
        Header: t("configurationmgttableheaders.updatedon"),
        accessor: "updatedOn",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   disableSortBy: true,
      //   Cell: (props) => {
      //     if(props.value === true){
      //       return "Active";
      //     }
      //     else{
      //       return "Inactive";
      //     }
      //   },
      // },
      // {
      //   Header: t("configurationmgttableheaders.action"),
      //   // accessor: "action",
      //   disableSortBy: true,

      //   Cell: (props) => {
      //     const [isdelete, setIsdelete] = useState(false);
      //     const deletePopUp = () => {
      //       setIsdelete(!isdelete);
      //     };
      //     const deleteTableDataById = async (id) => {
      //       let data = { id: id };
      //       var res = await putDataWithBody(deleteConfig, data, {});
      //       if (res.status == "success") {
      //         refreshConfigMasterTable(
      //           document.getElementById("config_type").value
      //         );
      //         toast.success(res.msg, {
      //           position: toast.POSITION.TOP_CENTER,
      //           autoClose: 2000, // Close after 2 seconds
      //         });
      //         setIsdelete(false);
      //       }
      //     };
      //     return (
      //       <div className="usertable__actionsicon">
      //         <Editicon
      //           onClick={() => addUpdateHandler(props.row.original)}
      //           value={props.value}
      //           className="cursor-pointer me-2"
      //         />

      //         {/* <span onClick={deletePopUp}>
      //           <Deleteicon
      //             value={props.value}
      //             className="cursor-pointer mr-1"
      //           />
      //         </span> */}

      //         {isdelete && (
      //           <Confirmpopup
      //             show={isdelete}
      //             title={t("deletepopuptext")}
      //             message={t("deletepopupmessage")}
      //             handleToggle={deletePopUp}
      //             row={props.row}
      //             name={props.row.original.value}
      //             deleteRow={deleteTableDataById}
      //             cancel={t("cancelbutton")}
      //             action={t("actionbutton")}
      //           />
      //         )}
      //       </div>
      //     );
      //   },
      // },
    ],
    [t]
  );

  return (
    <div className="create-user">
      <div className="datatable__container config_data_tbl_container">
        <div className="ps-3 searchRsrcContainer d-flex">
          <SelectComponent
            id={"config_type"}
            value={selectedConfigType}
            onChange={getConfigDataByCode}
            classList={"me-2"}
            options={resourceCategoryData}
          />
          <button
            className="btn btn-success btn-sm add_config"
            onClick={addNewHandler}>
            {t("configurationaddbuttonlabel")}
            {/* Add New */}
          </button>
        </div>

        {masterTypeData && (
          <Table
            className={"searchRsrcContainerTbl"}
            data={masterTypeData}
            columns={columns}
            numberOfRows={10}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              borderRadius: "12px",
            }}
          />
        )}
        {showPop && (
          <ModalResourceType
            titleValue={t("popup.addnew")}
            showSuccessMsg={showSuccessMsg}
            selectedConfigId={selectedConfigId}
            show={showPop}
            buttonLabel={"popup.save"}
            handleToggle={addNewHandler}
            popupLabel={t("popup.addvalue")}
            {...props}
          />
        )}
        {updatePopUp && (
          <ModalResourceType
            data={modalData}
            titleValue={t("popup.configpopuptitle")}
            showSuccessMsg={showSuccessMsg}
            selectedConfigId={selectedConfigId}
            show={updatePopUp}
            buttonLabel={t("popup.update")}
            handleToggle={addUpdateHandler}
            popupLabel={t("popup.configpopuplabel")}
            {...props}
          />
        )}
      </div>
      {/* <Modal show={isShow}>
    return (
        <div className="container  create-user">
            <div className="datatable__container">
                <div className="ps-3 w-50 searchRsrcContainer d-flex float-start position-relative">
                    <SelectComponent
                    onChange={getConfigDataByCode}
                    classList={"me-2 w-50"}
                    options={resourceCategoryData}
                    />
                    <button className="btn btn-success btn-sm add_config" onClick={addNewHandler}>Add New</button>
                   
                </div>

                {masterTypeData && (
                    <Table
                        data={masterTypeData}
                        columns={columns}
                        numberOfRows={5}
                        showExport={false}
                        showFilter={false}
                        showReset={false}
                        onExpand={false}
                        headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
                        tableStyle={{ border: "none", padding: "0px" }}
                    />
                )}
                {showPop && <ModalResourceType 
                    titleValue={"Add New"}
                    showSuccessMsg={showSuccessMsg}
                    selectedConfigId={selectedConfigId} show={showPop}
                    handleToggle={addNewHandler}
                    popupLabel= "Value"
                    {...props} />}
            </div>
            {/* <Modal show={isShow}>
                <Modal.Header closeButton onClick={hideModal}>
                    <Modal.Title>Alert !</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete the role ?</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        className="delete_user_btn"
                        // onClick={hideModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        // onClick={deleteRoleByRoleId}
                        variant="dark"
                        className="create_user_btn"
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal> */}
    </div>
  );
});

export default MasterDataTable;
